import React, { useState, useEffect } from "react";
import { useForm } from "antd/lib/form/Form";
import { Modal, Button, Form, Input, message } from "antd";

import "./index.css"; // Import your custom CSS file

export default function ConfirmActivateOrDeactivateUserComponent({ modalInfo, onClose }) {
    const [activateDeactivateFrom] = useForm();

    const [showModal, setShowModal] = useState(false);
    const [updateDisabled, setUpdateDisabled] = useState(true);

    const remarkValue = Form.useWatch([], activateDeactivateFrom);

    useEffect(() => {
        setShowModal(modalInfo?.show);
    }, [modalInfo]);

    useEffect(() => {
        activateDeactivateFrom
            .validateFields(["reasonRemark"], {
                validateOnly: true,
            })
            .then(
                (value) =>
                    value && value?.reasonRemark && setUpdateDisabled(false)
            )
            .catch(() => setUpdateDisabled(true));
    }, [activateDeactivateFrom, remarkValue]);

    const changeStatus = () => {
        const { type, rowData } = modalInfo || {};
        const { reasonRemark } = activateDeactivateFrom.getFieldsValue();

        onClose(rowData?.id, type == "activate" ? true : false, reasonRemark, rowData?.tempAuthPersonnel);
    };

    const capitalizedStr = (str = "") => {
        return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    };

    return (
        <Modal
            open={showModal}
            footer={null}
            onCancel={() => {
                onClose();
            }}
        >
            <h2
                style={{
                    fontWeight: "bold",
                    textAlign: "center",
                }}
            >
                {capitalizedStr(modalInfo?.type)}{" "}
                {capitalizedStr(modalInfo?.rowData?.personName)}
            </h2>
            <Form
                layout="vertical"
                style={{ padding: "0px 20px 0px 20px" }}
                width={200}
                requiredMark={false}
                form={activateDeactivateFrom}
                onFinish={() => {
                    changeStatus();
                }}
                className="custom-confirm-activate-deactivate-form"
            >
                <Form.Item
                    label={`Please state the reason to ${capitalizedStr(
                        modalInfo?.type
                    )}`}
                    style={{ color: "grey", fontStyle: "italic" }}
                    name="reasonRemark"
                    rules={[
                        {
                            required: true,
                            message: "Please enter the reason",
                        },
                        {
                            max: 255,
                            message: "Reason cannot exceed 255 characters",
                        },
                    ]}
                >
                    <Input type={"text"} className="form-input-field" />
                </Form.Item>

                <Form.Item>
                    <Button
                        className="add-new-user-button custom-disabled-button"
                        type="primary"
                        style={{ width: "100%" }}
                        htmlType="submit"
                        disabled={updateDisabled}
                    >
                        Update
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    );
}
