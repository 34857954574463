import { config } from "../../config";
import {
    removeAuthorizedNric,
    removeBranchId,
    removeToken,
    removeUnverifiedAccount,
    removeIsAuthorizeToAccessUserManagement,
    removeCompanyId,
    removeLoggedInUsernaame,
    removeAuthUid,
    getAuthUid,
    removeUserRole,
    getCompanyId,
    removeAnnouncementShown,
    removePermissions,
    removeCipheredPassword,
    removeRedirectParameter,
    removeLoggedInTime,
} from "../local";
import { getToken } from "../local";
import moment from "moment";
import { parseDate } from "../../util";
import {
    InSaveSellerProceedRequest,
    InSaveSellerProceedResponse,
    GetInTransferAmountRequest,
    GetInTransferAmountResponse,
    GetInTransferDetailRequest,
    GetInTransferDetailResponse,
    GetVehicleTypesResponse,
    InSaveSellerRequest,
    InSaveSellerResponse,
    InProcessPaymentRequest,
    InProcessPaymentResponse,
    InUpdateVehicleDetailsToJPJRequest,
    InUpdateVehicleDetailsToJPJResponse,
    InGetPrepareReceiptRequest,
    GetBranchListResponse,
    InventoryResponse,
    AccountDetailResponse,
    Create1MIDAccountResponse,
    GetVehicleMovementResponse,
    GetDistrictListResponse,
    UcdRegistrationRequest,
    UcdRegistrationResponse,
    InV2GetPrepareReceiptRequest,
    GetCityListResponse,
    Announcement,
    EncryptUrlResponse,
    DecryptUrlRequest,
    DecryptUrlResponse,
    bulkPaymentRequest,
    bulkPaymentResponse,
    searchAptTransactionsResponse,
    ActivateRemoteUserRequest,
} from "./types";
import { message } from "antd";
import {
    CompanyResponse,
    M2MProceedResponse,
    SaveBuyerRequest,
    VehicleResponse,
    M2MBuyerResponse,
    SaveProceedRequest,
    SaveProceedBulkRequest,
} from "../../pages/Member2MemberPage/types";
import {
    SaveBulkBuyerRequest,
    BulkM2MBuyerResponse,
    BulkM2MProceedResponse,
    BulkTransactionDetailResponse,
} from "../../pages/Member2MemberPageV2/types";
import {
    ERROR_MESSAGE_TIMEOUT,
    ERROR_MESSAGE_AUDIT_SERVER_ERROR,
    ERROR_MESSAGE_INTERNAL_SERVER_ERROR,
} from "../../constants";

const uId = getAuthUid();

function getHeaders() {
    const AUTH_TOKEN = getToken();
    return {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${AUTH_TOKEN}`,
        // 'Cache-Control': `no-store`,
        // 'Pragma': `no-cache`,
        // 'Content-Security-Policy': "base-uri 'self' https://app6.myeg.com.my; child-src 'self'; connect-src 'self'; default-src 'self' ; form-action 'self' https://dev1.myeg.com.my https://uat4.myeg.com.my/ https://app6.myeg.com.my; frame-ancestors 'self' https://app6.myeg.com.my; frame-src 'self' https://app6.myeg.com.my; manifest-src 'self' https://app6.myeg.com.my; media-src 'self' https://app6.myeg.com.my;object-src 'none'; script-src  'self' 'unsafe-eval'  'unsafe-inline' https://app6.myeg.com.my http://ajax.googleapis.com https://www.google.com/recaptcha http://appstats.myeg.com.my; style-src 'self' 'unsafe-inline' https://app6.myeg.com.my;img-src 'self' data: https://app6.myeg.com.my; font-src 'self' data:",
        // 'X-Frame-Options': 'SAMEORIGIN',
        // 'X-XSS-Protection': '1; mode=block',
        // 'X-Content-Type-Options': 'nosniff',
        // 'Feature-Policy': "vibrate 'self';  sync-xhr 'self'",
        // 'referrer-policy': 'same-origin',
        // 'Strict-Transport-Security': 'min-age=7776000; includeSubDomains',
        // 'Permissions-Policy': "fullscreen 'self' https://app6.myeg.com.my https://dev1.myeg.com.my https://uat4.myeg.com.my/; geolocation *; camera 'none'",
        // 'Strict-Transport-Security' : "max-age=10886400; includeSubDomains",
        // 'Access-Control-Allow-Credentials': 'true',
        // 'Set-Cookie' : 'incap_ses_*-*; HTTPOnly; Secure',
        // 'Set-Cookie' : 'visid_incap_*=*; HTTPOnly; Secure; incap_ses_*=*; HTTPOnly; Secure',
        "x-api-key": process.env.REACT_APP_X_API_KEY || "",
    };
}

function getHeadersWOToken() {
    return {
        "Content-Type": "application/json",
        Accept: "application/json",
        "x-api-key": process.env.REACT_APP_X_API_KEY || "",
    };
}

export const login = (username, password) => {
    return fetch(`${config.api}/etukarv2/login`, {
        method: "POST",
        headers: getHeadersWOToken(),
        body: JSON.stringify({
            username,
            password,
        }),
    }).then((res) => res.json());
};

export const forgotPassword = (username) => {
    return fetch(`${config.api}/etukarv2/forgotPassword/${username}`, {
        method: "POST",
        headers: getHeadersWOToken(),
        body: JSON.stringify({
            username,
        }),
    }).then((res) => res.json());
};

export const verifyResetPasswordLinkValidity = (encryptedString) => {
    return fetch(`${config.api}/etukarv2/checkResetPasswordLinkValidity`, {
        method: "POST",
        headers: getHeadersWOToken(),
        body: JSON.stringify({
            encryptedString,
        }),
    }).then((res) => res.json());
};

export const check2FA = () => {
    const headers = getHeaders();
    return fetch(`${config.api}/etukarv2/check2FA`, {
        headers,
    }).then((res) => res.json());
};

export const generate2FA = () => {
    const headers = getHeaders();
    return fetch(`${config.api}/etukarv2/generate2FA`, {
        headers,
    }).then((res) => res.json());
};
export const verifyOTP = (otp) => {
    const headers = getHeaders();
    return fetch(`${config.api}/etukarv2/verifyOtp/${otp}`, {
        headers,
    }).then((res) => verifyResponse(res));
};

export const getProfile = async () => {
    const headers = getHeaders();

    try {
        if (getToken() !== null) {
            const res = await fetch(`${config.api}/etukarv2/getProfile`, {
                headers,
            });

            //To cater ETK-455 return error message where when BE down
            if (
                res.status === 503 ||
                res.status === 404 ||
                res.status === 504
            ) {
                throw new Error(ERROR_MESSAGE_INTERNAL_SERVER_ERROR);
            }

            const textResponse = await res.clone().text();
            JSON.parse(textResponse);

            return verifyResponse(res);
        } else {
            return { error: "invalid_token" };
        }
    } catch (error) {
        throw new Error(ERROR_MESSAGE_INTERNAL_SERVER_ERROR);
    }
};

export const getRoles = () => {
    const headers = getHeaders();
    return fetch(`${config.api}/etukarv2/getRoles`, {
        headers,
    }).then((res) => verifyResponse(res));
};
export const isAccountExist = (icNo) => {
    const headers = getHeaders();
    return fetch(`${config.api}/etukarv2/isAccountExist/${icNo}`, {
        headers,
    }).then((res) => verifyResponse(res));
};

export const getRolesByUsername = (nric) => {
    const headers = getHeaders();
    return fetch(`${config.api}/etukarv2/getRoles/${nric}`, {
        headers,
    }).then((res) => verifyResponse(res));
};
export const updateProfile = (
    address1,
    address2,
    address3,
    city,
    mobileno,
    myegAdmin,
    nric,
    phoneno,
    postcode,
    state
) => {
    const headers = getHeaders();
    return fetch(`${config.api}/etukarv2/updateProfile`, {
        method: "POST",
        headers,
        body: JSON.stringify({
            address1,
            address2,
            address3,
            city,
            mobileno,
            myegAdmin,
            nric,
            phoneno,
            postcode,
            state,
        }),
    }).then((res) => res.json());
};

export const updateEmail = (
    currentEmail,
    myegAdmin,
    newEmail,
    branchUserId
) => {
    const headers = getHeaders();
    return fetch(`${config.api}/etukarv2/updateEmail`, {
        method: "POST",
        headers,
        body: JSON.stringify({
            currentEmail,
            myegAdmin,
            newEmail,
            branchUserId,
        }),
    }).then((res) => res.json());
};

export const changePassword = (currentPassword, newPassword, username) => {
    const headers = getHeaders();
    return fetch(`${config.api}/etukarv2/changePassword`, {
        method: "POST",
        headers,
        body: JSON.stringify({
            currentPassword,
            newPassword,
            username,
        }),
    }).then((res) => res.json());
};

export const activateAccount = (encryptedString) => {
    return fetch(`${config.api}/etukarv2/activateAccount`, {
        method: "POST",
        headers: getHeadersWOToken(),
        body: JSON.stringify({
            encryptedString,
        }),
    }).then((res) => res.json());
};

export const resetPassword = (encryptedString) => {
    return fetch(`${config.api}/etukarv2/resetPassword`, {
        method: "POST",
        headers: getHeadersWOToken(),
        body: JSON.stringify({
            encryptedString,
        }),
    }).then((res) => res.json());
};

export const getCompanyOfLoggedInUser = (companyId) => {
    const headers = getHeaders();
    return fetch(`${config.api}/etukarv2/viewCompany/${companyId}`, {
        headers,
    }).then((res) => res.json());
};

export const getUser = (id) => {
    const headers = getHeaders();
    return fetch(`${config.api}/etukarv2/viewUser/${id}`, {
        headers,
    }).then((res) => res.json());
};
export const updateCompany = (
    address1,
    address2,
    address3,
    city,
    coId,
    contactno,
    email,
    faxno,
    postcode,
    state,
    username
) => {
    const headers = getHeaders();
    return fetch(`${config.api}/etukarv2/updateCompany`, {
        method: "POST",
        headers,
        body: JSON.stringify({
            address1,
            address2,
            address3,
            city,
            coId,
            contactno,
            email,
            faxno,
            postcode,
            state,
            username,
        }),
    }).then((res) => res.json());
};

export const getBranchByBranchIdAndCoId = (coId, branchId) => {
    const headers = getHeaders();
    return fetch(`${config.api}/etukarv2/viewBranch/${coId}/${branchId}`, {
        headers,
    }).then((res) => res.json());
};

export const getCompanyAndBranchList = () => {
    const headers = getHeaders();
    return fetch(`${config.api}/etukarv2/getCompanyAndBranchList`, {
        headers,
    }).then((res) => res.json());
};

export const getBranchListByCoRegNo = (coRegNo) => {
    const headers = getHeaders();
    return fetch(`${config.api}/etukarv2/getBranchList`, {
        method: "POST",
        headers,
        body: JSON.stringify({ coRegNo }),
    }).then((res) => res.json());
};

export const activateOrDeactivateBranch = (branchId, coId, is_active) => {
    const headers = getHeaders();
    return fetch(`${config.api}/etukarv2/activateOrDeactivateBranch`, {
        method: "POST",
        headers,
        body: JSON.stringify({
            branchId,
            coId,
            is_active,
        }),
    }).then((res) => res.json());
};

export const getPrepaidBalance = (dealerCompanyROC) => {
    const headers = getHeaders();
    return fetch(`${config.api}/prepaid/getBalanceIN`, {
        method: "POST",
        headers,
        body: JSON.stringify({
            dealerCompanyROC,
        }),
    })
        .then((res) => verifyResponse(res))
        .then((res) => {
            if (res.balanceAmount === null) {
                return {
                    status: 201,
                    message: res.message,
                };
            } else {
                return {
                    status: 200,
                    ...res,
                };
            }
        });
};
export const getPrepaidReceiptIN = (
    inGetPrepareReceiptRequest: InGetPrepareReceiptRequest
) => {
    const headers = getHeaders();
    return fetch(`${config.api}/prepaid/getReceiptIN`, {
        method: "POST",
        headers,
        body: JSON.stringify(inGetPrepareReceiptRequest),
    })
        .then((response) => response.blob())
        .then((blob) => {
            const date = parseDate(moment.now());
            var url = window.URL.createObjectURL(blob);
            var a = document.createElement("a");
            a.href = url;
            a.download = `${inGetPrepareReceiptRequest.vehNo} ${date}.pdf`;
            document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
            a.click();
            a.remove(); //afterwards we remove the element again
        });
};

export const getReceiptAPT = (companyId, transferId, vehNo) => {
    const headers = getHeaders();
    return fetch(`${config.api}/prepaid/getReceiptAPT`, {
        method: "POST",
        headers,
        body: JSON.stringify({
            companyId,
            transferId,
            vehNo,
        }),
    })
        .then((response) => response.blob())
        .then((blob) => {
            const date = parseDate(moment.now());
            var url = window.URL.createObjectURL(blob);
            var a = document.createElement("a");
            a.href = url;
            a.download = `${vehNo} ${date}.pdf`;
            document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
            a.click();
            a.remove(); //afterwards we remove the element again
        });
};

export const getPrepaidReceiptOUT = (companyId, transferId, vehNo) => {
    const headers = getHeaders();
    return fetch(`${config.api}/prepaid/getReceiptOUT`, {
        method: "POST",
        headers,
        body: JSON.stringify({
            companyId,
            transferId,
            vehNo,
        }),
    })
        .then((response) => response.blob())
        .then((blob) => {
            const date = parseDate(moment.now());
            var url = window.URL.createObjectURL(blob);
            var a = document.createElement("a");
            a.href = url;
            a.download = `${vehNo} ${date}.pdf`;
            document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
            a.click();
            a.remove(); //afterwards we remove the element again
        });
};
/**
 * Example request body
 * {
  "currentLoginUserName": "string",
  "dealerCompanyROC": "string",
  "sellerInfo": {
    "address1": "string",
    "address2": "string",
    "address3": "string",
    "correspondentAddress": "string",
    "correspondentEmail": "string",
    "correspondentMobileNo": "string",
    "district": "string",
    "identificationNo": "string",
    "name": "string",
    "phoneNo": "string",
    "postcode": "string",
    "repIc": "string",
    "repIc2": "string",
    "repName": "string",
    "sellerType": 0,
    "state": "string"
  },
  "vehicleInfo": {
    "carMake": "string",
    "carModel": "string",
    "chassisNo": "string",
    "engineNo": "string",
    "isWhiteListVehicle": true,
    "lkmoption": 0,
    "manufactureYear": "string",
    "ownerIdCat": "string",
    "vehicleRegistrationNo": "string",
    "vehicleType": "string"
  }
}
 */
export const saveInSeller = (
    inSaveSellerRequest: InSaveSellerRequest
): Promise<InSaveSellerResponse> => {
    const headers = getHeaders();
    inSaveSellerRequest.uId = uId;
    return fetch(`${config.api}/etukarv2/in/save-seller`, {
        method: "POST",
        headers,
        body: JSON.stringify(inSaveSellerRequest),
    }).then((res) => verifyResponse(res));
};

export const inSaveSellerProceed = (
    inSaveSellerProceedRequest: InSaveSellerProceedRequest
): Promise<InSaveSellerProceedResponse> => {
    const headers = getHeaders();
    inSaveSellerProceedRequest.uId = uId;
    return fetch(`${config.api}/etukarv2/in/save-seller-proceed`, {
        method: "POST",
        headers,
        body: JSON.stringify(inSaveSellerProceedRequest),
    }).then((res) => verifyResponse(res));
};

export const getAdminUsers = (coId, branchId) => {
    const headers = getHeaders();
    return fetch(`${config.api}/etukarv2/getAdminUsers/${coId}/${branchId}`, {
        headers,
    }).then((res) => verifyResponse(res));
};

export const getAdminUsersForOut = (coId, branchId) => {
    const headers = getHeaders();
    return fetch(
        `${config.api}/etukarv2/getAdminUsersForOut/${coId}/${branchId}`,
        {
            headers,
        }
    ).then((res) => verifyResponse(res));
};

export const getAdminUsersForCompany = (coRegNo) => {
    const headers = getHeaders();
    return fetch(`${config.api}/etukarv2/getAdminUsersForCompany`, {
        method: "POST",
        headers,
        body: JSON.stringify({ coRegNo }),
    }).then((res) => verifyResponse(res));
};

export const getAdminUsersForCompanyForOut = (coRegNo) => {
    const headers = getHeaders();
    return fetch(`${config.api}/etukarv2/getAdminUsersForCompanyForOut`, {
        method: "POST",
        headers,
        body: JSON.stringify({ coRegNo }),
    }).then((res) => verifyResponse(res));
};

export const verifyApproverOTP = (nric, otp) => {
    const headers = getHeaders();
    return fetch(`${config.api}/etukarv2/verifyApproverOtp/${nric}/${otp}`, {
        headers,
    }).then((res) => verifyResponse(res));
};
export const processPaymentIn = (
    inProcessPaymentRequest: InProcessPaymentRequest
): Promise<InProcessPaymentResponse> => {
    const headers = getHeaders();
    return fetch(`${config.api}/prepaid/processPaymentIN`, {
        method: "POST",
        headers,
        body: JSON.stringify(inProcessPaymentRequest),
    }).then((res) => verifyResponse(res));
};
export const processPaymentOut = (dealerCompanyROC, transferId) => {
    const headers = getHeaders();
    return fetch(`${config.api}/prepaid/processPaymentOUT`, {
        method: "POST",
        headers,
        body: JSON.stringify({
            dealerCompanyROC,
            transferId,
        }),
    }).then((res) => verifyResponse(res));
};
/**
    address1,
    address2,
    address3,
    branchName,
    city,
    coId,
    contactPersonName,
    contactno,
    email,
    faxno,
    is_active,
    is_hq,
    postcode,
    state,
    username
 */

export const addBranch = (branch) => {
    const headers = getHeaders();
    return fetch(`${config.api}/etukarv2/addBranch`, {
        method: "POST",
        headers,
        body: JSON.stringify({
            ...branch,
        }),
    }).then((res) => res.json());
};
/**
    address1,
    address2,
    address3,
    branchId,
    branchName,
    city,
    coId,
    contactPersonName,
    contactno,
    email,
    faxno,
    is_active,
    is_hq,
    postcode,
    state,
    username
 */
export const updateBranch = (branch) => {
    const headers = getHeaders();
    return fetch(`${config.api}/etukarv2/updateBranch`, {
        method: "POST",
        headers,
        body: JSON.stringify({
            ...branch,
        }),
    }).then((res) => res.json());
};
export const addBranchUser = (
    branchId,
    coId,
    contactNumber,
    email,
    nricNo,
    personName,
    roles,
) => {
    const headers = getHeaders();
    return fetch(`${config.api}/etukarv2/addUser`, {
        method: "POST",
        headers,
        body: JSON.stringify({
            branchId,
            coId,
            contactNumber,
            email,
            nricNo,
            personName,
            roles,
        }),
    }).then((res) => res.json());
};

export const addUserFromAdmin = (
    branchId,
    coId,
    contactNumber,
    email,
    nricNo,
    personName,
    roles
) => {
    const headers = getHeaders();
    return fetch(`${config.api}/etukarv2/addUserFromAdmin`, {
        method: "POST",
        headers,
        body: JSON.stringify({
            branchId,
            coId,
            contactNumber,
            email,
            nricNo,
            personName,
            roles,
        }),
    }).then((res) => res.json());
};

export const updateBranchUser = async (
    branchId: number,
    coId: number,
    contactNumber: string,
    email: string,
    nricNo: string,
    personId: number,
    personName: string,
    roles: string[],
    isTempUser: boolean
) => {
    const headers = getHeaders();
    const res = await fetch(`${config.api}/etukarv2/updateUser`, {
        method: "POST",
        headers,
        body: JSON.stringify({
            branchId,
            coId,
            contactNumber,
            email,
            nricNo,
            personId,
            personName,
            roles,
            isTempUser,
        }),
    });
    return await res.json();
};

export const updateUserFromAdmin = async (
    branchId: number,
    coId: number,
    contactNumber: string,
    email: string,
    nricNo: string,
    personId: number,
    personName: string,
    roles: string[],
    isTempUser: boolean
) => {
    const headers = getHeaders();
    const res = await fetch(`${config.api}/etukarv2/updateUserFromAdmin`, {
        method: "POST",
        headers,
        body: JSON.stringify({
            branchId,
            coId,
            contactNumber,
            email,
            nricNo,
            personId,
            personName,
            roles,
            isTempUser,
        }),
    });
    return await res.json();
};

export const activateOrDeactivateBranchUser = (
    is_active,
    personId,
    companyROC,
    remarks = "",
    tempAuthPersonnel,
) => {
    const headers = getHeaders();
    return fetch(`${config.api}/etukarv2/activeOrDeactiveUser`, {
        method: "POST",
        headers,
        body: JSON.stringify({
            is_active,
            personId,
            companyROC,
            remarks,
            tempAuthPersonnel,
        }),
    }).then((res) => res.json());
};

/**
 * 10/2/2022 daniel.kwok
 * done this way because possible in the future logout is an api
 */
export const logout = async () => {
    await removePermissions();
    await removeUid(); //api call to BE to remove in db
    await removeAuthUid();
    await removeAuthorizedNric();
    await removeBranchId();
    await removeCompanyId();
    await removeUnverifiedAccount();
    await removeIsAuthorizeToAccessUserManagement();
    await removeLoggedInUsernaame();
    await removeAnnouncementShown();
    await removeCipheredPassword();
    await removeRedirectParameter();
    await removeLoggedInTime();
    return removeToken();
};
/**
 * What is this API doing?
 * Need to call this API upon successful payment.
 * This API is used for submit payment details to JPJ upon successful payment.
 *
 * @returns
 */
export const inUpdateVehicleDetailsToJPJ = (
    inUpdateVehicleDetailsToJPJ: InUpdateVehicleDetailsToJPJRequest
): Promise<InUpdateVehicleDetailsToJPJResponse> => {
    const headers = getHeaders();
    inUpdateVehicleDetailsToJPJ.uId = uId;
    return fetch(`${config.api}/etukarv2/in/update-vehicle-details-to-jpj`, {
        method: "POST",
        headers,
        body: JSON.stringify(inUpdateVehicleDetailsToJPJ),
    }).then((res) => verifyResponse(res));
};

export const outUpdateVehicleDetailsToJPJ = (transferId) => {
    const headers = getHeaders();
    return fetch(`${config.api}/etukarv2/out/update-to-jpj-out`, {
        method: "POST",
        headers,
        body: JSON.stringify({
            transferId,
            uId,
        }),
    }).then((res) => verifyResponse(res));
};
export const deleteBranchuser = (id) => {
    const headers = getHeaders();
    return fetch(`${config.api}/etukarv2/deleteUser/${id}`, {
        headers,
    }).then((res) => res.json());
};

export const getInTransferByTransferId = (
    getInTransferDetailRequest: GetInTransferDetailRequest
): Promise<GetInTransferDetailResponse> => {
    const headers = getHeaders();
    getInTransferDetailRequest.uId = uId;
    getInTransferDetailRequest.companyId = getCompanyId();
    return fetch(`${config.api}/etukarv2/in/get-transaction-details`, {
        method: "POST",
        headers,
        body: JSON.stringify(getInTransferDetailRequest),
    }).then((res) => res.json());
};

export const getInTransferAmountByTransferId = (
    getInTransferAmountRequest: GetInTransferAmountRequest
): Promise<GetInTransferAmountResponse> => {
    const headers = getHeaders();
    return fetch(`${config.api}/etukarv2/in/get-transaction-amount`, {
        method: "POST",
        headers,
        body: JSON.stringify(getInTransferAmountRequest),
    }).then((res) => res.json());
};

export const getInTransferListByCompanyRegNo = (dealerCompanyROC) => {
    const headers = getHeaders();
    return fetch(`${config.api}/etukarv2/in/get-transaction-list`, {
        method: "POST",
        headers,
        body: JSON.stringify({
            dealerCompanyROC,
        }),
    }).then((res) => res.json());
};

export const getInVehicleTypes = (): Promise<GetVehicleTypesResponse> => {
    const headers = getHeaders();
    return fetch(`${config.api}/etukarv2/in/get-vehicle-types`, {
        headers,
    }).then((res) => verifyResponse(res));
};

export const InTransferInquiryWithJPJ = async (
    transferId: number
): Promise<InSaveSellerProceedResponse> => {
    const headers = getHeaders();
    const res = await fetch(`${config.api}/etukarv2/in/inquiry-with-jpj`, {
        method: "POST",
        headers,
        body: JSON.stringify({
            transferId,
            uId,
        }),
    });
    return await res.json();
};

export const getOutTransferListByCompanyRegNo = (dealerCompanyROC) => {
    const headers = getHeaders();
    return fetch(`${config.api}/etukarv2/out/get-transaction-list`, {
        method: "POST",
        headers,
        body: JSON.stringify({
            dealerCompanyROC,
        }),
    }).then((res) => res.json());
};

export const getAPTTransferListByCompanyRegNo = (dealerCompanyROC) => {
    const headers = getHeaders();
    return fetch(`${config.api}/etukarv2/in/get-apt-transaction-list`, {
        method: "POST",
        headers,
        body: JSON.stringify({
            dealerCompanyROC,
        }),
    }).then((res) => res.json());
};

export const getOutTransferAmountByTransferId = (transferId) => {
    const headers = getHeaders();
    return fetch(`${config.api}/etukarv2/out/get-transaction-amount`, {
        method: "POST",
        headers,
        body: JSON.stringify({
            transferId,
        }),
    }).then((res) => verifyResponse(res));
};

export const getOutTransferByTransferId = (
    transferId,
    moduleType,
    companyId
) => {
    const headers = getHeaders();
    return fetch(`${config.api}/etukarv2/out/get-transaction-details`, {
        method: "POST",
        headers,
        body: JSON.stringify({
            transferId,
            uId,
            moduleType,
            companyId,
        }),
    }).then((res) => verifyResponse(res));
};

export const getOutVehicleTypes = () => {
    const headers = getHeaders();
    return fetch(`${config.api}/etukarv2/out/get-vehicle-types`, {
        headers,
    }).then((res) => verifyResponse(res));
};

export const saveOutSeller = (
    branchId,
    currentLoginUserName,
    dealerCompanyROC,
    isOutTxn,
    sellerInfo,
    transactionType,
    vehicleInfo
) => {
    const headers = getHeaders();
    return fetch(`${config.api}/etukarv2/out/save-seller`, {
        method: "POST",
        headers,
        body: JSON.stringify({
            branchId,
            currentLoginUserName,
            dealerCompanyROC,
            isOutTxn,
            sellerInfo,
            transactionType,
            vehicleInfo,
            uId,
        }),
    }).then((res) => verifyResponse(res));
};

export const saveOutBuyer = (
    buyerInfo,
    currentLoginUserName,
    dealerCompanyROC,
    transferId,
    verifiedBuyerNric
) => {
    const headers = getHeaders();
    const dealerCompanyID = getCompanyId();
    return fetch(`${config.api}/etukarv2/out/save-buyer`, {
        method: "POST",
        headers,
        body: JSON.stringify({
            buyerInfo,
            currentLoginUserName,
            dealerCompanyROC,
            transferId,
            verifiedBuyerNric,
            uId,
            dealerCompanyID,
        }),
    }).then((res) => verifyResponse(res));
};
export const saveOutSellerBuyerInquiry = (approverNRIC, transferId) => {
    const headers = getHeaders();
    return fetch(`${config.api}/etukarv2/out/seller-buyer-inquiry`, {
        method: "POST",
        headers,
        body: JSON.stringify({
            approverNRIC,
            transferId,
            uId,
        }),
    }).then((res) => verifyResponse(res));
};

export const i2iSaveOutSellerBuyerInquiry = (transferId) => {
    const headers = getHeaders();
    return fetch(`${config.api}/etukarv2/out/seller-buyer-inquiry`, {
        method: "POST",
        headers,
        body: JSON.stringify({
            transferId,
            uId,
        }),
    }).then((res) => verifyResponse(res));
};

export const getCompanyDetails = (coRegNo) => {
    const headers = getHeaders();
    return fetch(`${config.api}/etukarv2/getCompanyDetails`, {
        method: "POST",
        headers,
        body: JSON.stringify({ coRegNo }),
    }).then((res) => res.json());
};

export const getMysikapLink = (coId) => {
    const headers = getHeaders();
    return fetch(`${config.api}/etukarv2/getJpjMysikapLink/${coId}`, {
        headers,
    }).then((res) => res.json());
};

export const getVehicleDetails = (
    branchId: string,
    companyId: string,
    vehicleNumber: string
): Promise<VehicleResponse> => {
    const headers = getHeaders();
    return fetch(`${config.api}/inventory/retrieveVehicleDetails`, {
        method: "POST",
        headers,
        body: JSON.stringify({
            branchId,
            companyId,
            vehicleNumber,
        }),
    }).then((res) => verifyResponse(res));
};

export const getInventoryList = (
    branchId: string,
    dealerCompanyROC: string,
    pageLimit: number,
    pageNumber: number,
    search: string,
    transactionDate: string,
    transactionType: string,
    carRegistrationNo: string
): Promise<InventoryResponse> => {
    const headers = getHeaders();
    return fetch(`${config.api}/inventory/getInventoryList`, {
        method: "POST",
        headers,
        body: JSON.stringify({
            branchId,
            dealerCompanyROC,
            pageLimit,
            pageNumber,
            search,
            transactionDate,
            transactionType,
            carRegistrationNo,
        }),
    }).then((res) => res.json());
};

export const m2mBuyer = async (
    buyerRequest: SaveBuyerRequest
): Promise<M2MBuyerResponse> => {
    const headers = getHeaders();
    buyerRequest.uId = uId;
    const res = await fetch(`${config.api}/etukarv2/in/save-m2m-buyer`, {
        method: "POST",
        headers,
        body: JSON.stringify({
            ...buyerRequest,
        }),
    });
    return await res.json();
};

export const m2mProceed = async (
    buyerRequest: SaveProceedRequest
): Promise<M2MProceedResponse> => {
    const headers = getHeaders();
    buyerRequest.uId = uId;
    const res = await fetch(`${config.api}/etukarv2/in/save-m2m-proceed`, {
        method: "POST",
        headers,
        body: JSON.stringify({
            ...buyerRequest,
        }),
    });
    return await res.json();
};

export const getAccountDetails = async (
    nricNo: string
): Promise<AccountDetailResponse> => {
    const headers = getHeaders();
    const res = await fetch(
        `${config.api}/etukarv2/getAccountDetails/${nricNo}`,
        {
            headers,
        }
    );
    return await res.json();
};

export const create1MIDAccount = async (
    address1,
    address2,
    address3,
    birthDate,
    birthPlace,
    citizenship,
    city,
    confirmEmail,
    email,
    gender,
    mobileno,
    name,
    nric,
    otherBranchAddUser,
    phoneno,
    postcode,
    race,
    religion,
    state
): Promise<Create1MIDAccountResponse> => {
    const headers = getHeaders();
    const res = await fetch(`${config.api}/etukarv2/create1MIDAccount`, {
        method: "POST",
        headers,
        body: JSON.stringify({
            address1,
            address2,
            address3,
            birthDate,
            birthPlace,
            citizenship,
            city,
            confirmEmail,
            email,
            gender,
            mobileno,
            name,
            nric,
            otherBranchAddUser,
            phoneno,
            postcode,
            race,
            religion,
            state,
        }),
    });
    return await res.json();
};

export const update1MIDAccount = async (
    address1,
    address2,
    address3,
    birthDate,
    birthPlace,
    citizenship,
    city,
    confirmEmail,
    email,
    gender,
    mobileno,
    name,
    nric,
    otherBranchAddUser,
    phoneno,
    postcode,
    race,
    religion,
    state
): Promise<Create1MIDAccountResponse> => {
    const headers = getHeaders();
    const res = await fetch(`${config.api}/etukarv2/update1MIDAccount`, {
        method: "POST",
        headers,
        body: JSON.stringify({
            address1,
            address2,
            address3,
            birthDate,
            birthPlace,
            citizenship,
            city,
            confirmEmail,
            email,
            gender,
            mobileno,
            name,
            nric,
            otherBranchAddUser,
            phoneno,
            postcode,
            race,
            religion,
            state,
        }),
    });
    return await res.json();
};

export const getVehicleMovementReport = async (
    dealerCompanyROC: string,
    fromDate: string,
    toDate: string,
    searchParam: string,
    search: string
): Promise<GetVehicleMovementResponse> => {
    const headers = getHeaders();
    const res = await fetch(`${config.api}/etukarv2/in/get-movement-reports`, {
        method: "POST",
        headers,
        body: JSON.stringify({
            dealerCompanyROC,
            fromDate,
            toDate,
            search,
            searchParam,
        }),
    });

    return await res.json();
};

export const getOutPaymentReportList = async (
    branchId: number,
    dealerCompanyROC: string,
    pageLimit: number,
    pageNumber: number,
    fromDate: string,
    toDate: string,
    carRegistrationNo: string,
    serviceId: string,
    buyerNric: string,
    sellerNric: string,
    transactionDate: string
) => {
    const headers = getHeaders();
    const res = await fetch(
        `${config.api}/etukarv2/out/get-payment-report-list`,
        {
            method: "POST",
            headers,
            body: JSON.stringify({
                branchId,
                dealerCompanyROC,
                pageLimit,
                pageNumber,
                fromDate,
                toDate,
                carRegistrationNo,
                serviceId,
                buyerNric,
                sellerNric,
                transactionDate,
            }),
        }
    );

    return await res.json();
};

export const geti2iPaymentReportList = async (
    branchId: number,
    dealerCompanyROC: string,
    pageLimit: number,
    pageNumber: number
) => {
    const headers = getHeaders();
    const res = await fetch(
        `${config.api}/etukarv2/out/get-i2i-payment-report-list`,
        {
            method: "POST",
            headers,
            body: JSON.stringify({
                branchId,
                dealerCompanyROC,
                pageLimit,
                pageNumber,
            }),
        }
    );

    return await res.json();
};

export const getAptPaymentReportList = async (
    dealerCompanyROC: string,
    pageLimit: number,
    pageNumber: number
) => {
    const headers = getHeaders();
    const res = await fetch(
        `${config.api}/etukarv2/in/get-apt-payment-report-list`,
        {
            method: "POST",
            headers,
            body: JSON.stringify({ dealerCompanyROC, pageLimit, pageNumber }),
        }
    );

    return await res.json();
};

export const getInPaymentReportList = async (
    dealerCompanyROC: string,
    pageLimit: number,
    pageNumber: number,
    fromDate: string,
    toDate: string,
    carRegistrationNo: string,
    serviceId: string,
    buyerNric: string,
    sellerNric: string,
    transactionDate: string
) => {
    const headers = getHeaders();
    const res = await fetch(
        `${config.api}/etukarv2/in/get-payment-report-list`,
        {
            method: "POST",
            headers,
            body: JSON.stringify({
                dealerCompanyROC,
                pageLimit,
                pageNumber,
                fromDate,
                toDate,
                carRegistrationNo,
                serviceId,
                buyerNric,
                sellerNric,
                transactionDate,
            }),
        }
    );

    return await res.json();
};

export const getInventoryListCount = async (
    branchId: number,
    companyROC: string
) => {
    const headers = getHeaders();
    const res = await fetch(
        `${config.api}/inventory/retrieveInventoryCount/${companyROC}/${branchId}`,
        {
            headers,
        }
    );

    return await res.json();
};

export const getOutPaymentReportCount = async (
    branchId: number,
    dealerCompanyROC: string,
    fromDate: string,
    toDate: string,
    carRegistrationNo: string,
    serviceId: string,
    buyerNric: string,
    sellerNric: string,
    transactionDate: string
) => {
    const headers = getHeaders();
    const res = await fetch(
        `${config.api}/etukarv2/out/get-payment-report-count`,
        {
            method: "POST",
            headers,
            body: JSON.stringify({
                branchId,
                dealerCompanyROC,
                fromDate,
                toDate,
                carRegistrationNo,
                serviceId,
                buyerNric,
                sellerNric,
                transactionDate,
            }),
        }
    );

    return res.json();
};

export const geti2iPaymentReportCount = async (
    branchId: number,
    dealerCompanyROC: string
) => {
    const headers = getHeaders();
    const res = await fetch(
        `${config.api}/etukarv2/out/get-i2i-payment-report-count`,
        {
            method: "POST",
            headers,
            body: JSON.stringify({ branchId, dealerCompanyROC }),
        }
    );

    return res.json();
};

export const getAptPaymentReportCount = async (dealerCompanyROC: string) => {
    const headers = getHeaders();
    const res = await fetch(
        `${config.api}/etukarv2/in/get-apt-Payment-report-count`,
        {
            method: "POST",
            headers,
            body: JSON.stringify({ dealerCompanyROC }),
        }
    );

    return res.json();
};

export const getInPaymentReportCount = async (
    dealerCompanyROC: string,
    fromDate: string,
    toDate: string,
    carRegistrationNo: string,
    serviceId: string,
    buyerNric: string,
    sellerNric: string,
    transactionDate: string
) => {
    const headers = getHeaders();
    const res = await fetch(
        `${config.api}/etukarv2/in/get-Payment-report-count`,
        {
            method: "POST",
            headers,
            body: JSON.stringify({
                dealerCompanyROC,
                fromDate,
                toDate,
                carRegistrationNo,
                serviceId,
                buyerNric,
                sellerNric,
                transactionDate,
            }),
        }
    );

    return res.json();
};

export const getOutTransactionCount = async (
    branchId: number,
    dealerCompanyROC: string
) => {
    const headers = getHeaders();
    const res = await fetch(
        `${config.api}/etukarv2/out/get-transaction-count-pagination`,
        {
            method: "POST",
            headers,
            body: JSON.stringify({ branchId, dealerCompanyROC }),
        }
    );

    return res.json();
};

export const getOutTransactionList = async (
    branchId: number,
    dealerCompanyROC: string,
    pageLimit: number,
    pageNumber: number,
    sort: string,
    status: string
) => {
    const headers = getHeaders();
    const res = await fetch(
        `${config.api}/etukarv2/out/get-transaction-list-pagination`,
        {
            method: "POST",
            headers,
            body: JSON.stringify({
                branchId,
                dealerCompanyROC,
                pageLimit,
                pageNumber,
                sort,
                status,
            }),
        }
    );

    return res.json();
};

export const geti2iTransactionCount = async (
    branchId: number,
    dealerCompanyROC: string
) => {
    const headers = getHeaders();
    const res = await fetch(
        `${config.api}/etukarv2/out/get-i2i-transaction-count-pagination`,
        {
            method: "POST",
            headers,
            body: JSON.stringify({ branchId, dealerCompanyROC }),
        }
    );

    return res.json();
};

export const geti2iTransactionList = async (
    branchId: number,
    dealerCompanyROC: string,
    pageLimit: number,
    pageNumber: number,
    sort: string,
    status: string
) => {
    const headers = getHeaders();
    const res = await fetch(
        `${config.api}/etukarv2/out/get-i2i-transaction-list-pagination`,
        {
            method: "POST",
            headers,
            body: JSON.stringify({
                branchId,
                dealerCompanyROC,
                pageLimit,
                pageNumber,
                sort,
                status,
            }),
        }
    );
    return await res.json();
};

export const getAptTransactionCount = async (dealerCompanyROC: string) => {
    const headers = getHeaders();
    const res = await fetch(
        `${config.api}/etukarv2/in/get-apt-transaction-count-pagination`,
        {
            method: "POST",
            headers,
            body: JSON.stringify({ dealerCompanyROC }),
        }
    );

    return res.json();
};

export const getAptTransactionList = async (
    dealerCompanyROC: string,
    pageLimit: number,
    pageNumber: number,
    sort: string,
    status: string
) => {
    const headers = getHeaders();
    const res = await fetch(
        `${config.api}/etukarv2/in/get-apt-transaction-list-pagination`,
        {
            method: "POST",
            headers,
            body: JSON.stringify({
                dealerCompanyROC,
                pageLimit,
                pageNumber,
                sort,
                status,
            }),
        }
    );

    return res.json();
};

export const getInTransactionCount = async (dealerCompanyROC: string) => {
    const headers = getHeaders();
    const res = await fetch(
        `${config.api}/etukarv2/in/get-transaction-count-pagination`,
        {
            method: "POST",
            headers,
            body: JSON.stringify({ dealerCompanyROC }),
        }
    );

    return res.json();
};

export const getInTransactionList = async (
    dealerCompanyROC: string,
    pageLimit: number,
    pageNumber: number,
    sort: string,
    status: string
) => {
    const headers = getHeaders();
    const res = await fetch(
        `${config.api}/etukarv2/in/get-transaction-list-pagination`,
        {
            method: "POST",
            headers,
            body: JSON.stringify({
                dealerCompanyROC,
                pageLimit,
                pageNumber,
                sort,
                status,
            }),
        }
    );

    return res.json();
};

export const getPendingi2iTransactionCount = async (
    branchId: number,
    dealerCompanyROC: string
) => {
    const headers = getHeaders();
    const res = await fetch(
        `${config.api}/etukarv2/out/get-i2i-pending-count`,
        {
            method: "POST",
            headers,
            body: JSON.stringify({ branchId, dealerCompanyROC }),
        }
    );

    return res.json();
};

export const getPendingOutTransactionCount = async (
    branchId: number,
    dealerCompanyROC: string
) => {
    const headers = getHeaders();
    const res = await fetch(
        `${config.api}/etukarv2/out/get-out-pending-count`,
        {
            method: "POST",
            headers,
            body: JSON.stringify({ branchId, dealerCompanyROC }),
        }
    );

    return res.json();
};

export const getPendingi2iTransactionList = async (
    branchId: number,
    dealerCompanyROC: string,
    pageLimit: number,
    pageNumber: number,
    sort: string,
    status: string
) => {
    const headers = getHeaders();
    const res = await fetch(`${config.api}/etukarv2/out/get-i2i-pending-list`, {
        method: "POST",
        headers,
        body: JSON.stringify({
            branchId,
            dealerCompanyROC,
            pageLimit,
            pageNumber,
            sort,
            status,
        }),
    });
    return await res.json();
};

export const getPendingOutTransactionList = async (
    branchId: number,
    dealerCompanyROC: string,
    pageLimit: number,
    pageNumber: number,
    sort: string,
    status: string
) => {
    const headers = getHeaders();
    const res = await fetch(`${config.api}/etukarv2/out/get-out-pending-list`, {
        method: "POST",
        headers,
        body: JSON.stringify({
            branchId,
            dealerCompanyROC,
            pageLimit,
            pageNumber,
            sort,
            status,
        }),
    });
    return await res.json();
};

export const getPendingAptTransactionCount = async (
    dealerCompanyROC: string
) => {
    const headers = getHeaders();
    const res = await fetch(
        `${config.api}/etukarv2/in/get-pending-apt-transaction-count`,
        {
            method: "POST",
            headers,
            body: JSON.stringify({ dealerCompanyROC }),
        }
    );

    return res.json();
};

export const getPendingAptTransactionList = async (
    dealerCompanyROC: string,
    pageLimit: number,
    pageNumber: number
) => {
    const headers = getHeaders();
    const res = await fetch(
        `${config.api}/etukarv2/in/get-pending-apt-transaction-list`,
        {
            method: "POST",
            headers,
            body: JSON.stringify({ dealerCompanyROC, pageLimit, pageNumber }),
        }
    );

    return res.json();
};

export const getPendingInTransactionCount = async (
    dealerCompanyROC: string
) => {
    const headers = getHeaders();
    const res = await fetch(
        `${config.api}/etukarv2/in/get-pending-transaction-count`,
        {
            method: "POST",
            headers,
            body: JSON.stringify({ dealerCompanyROC }),
        }
    );

    return res.json();
};

export const getPendingInTransactionList = async (
    dealerCompanyROC: string,
    pageLimit: number,
    pageNumber: number,
    sort: string,
    status: string
) => {
    const headers = getHeaders();
    const res = await fetch(
        `${config.api}/etukarv2/in/get-pending-transaction-list`,
        {
            method: "POST",
            headers,
            body: JSON.stringify({
                dealerCompanyROC,
                pageLimit,
                pageNumber,
                sort,
                status,
            }),
        }
    );

    return res.json();
};

export const getListForFilterVehicleNumber = async (
    pageLimit: number,
    pageNumber: number,
    serviceId: string,
    vehicleNumber: string,
    companyId: number
) => {
    const headers = getHeaders();
    try {
        const response = await timeoutPromise(
            3 * 60 * 1000,
            fetch(`${config.api}/etukarv2/audit/getListAuditTrailByVehicle`, {
                method: "POST",
                headers,
                body: JSON.stringify({
                    pageLimit,
                    pageNumber,
                    serviceId,
                    vehicleNumber,
                    companyId,
                }),
            })
        );
        const res = response as Response;
        if (res !== null && res && res.ok) {
            return res.json();
        } else {
            throw "Unsuccessful response received";
        }
    } catch (error) {
        if (error instanceof Error && error.message.includes("timeout")) {
            throw ERROR_MESSAGE_TIMEOUT;
        } else {
            throw ERROR_MESSAGE_AUDIT_SERVER_ERROR;
        }
    }
};

export const getCountForFilterVehicleNumber = async (
    serviceId: string,
    vehicleNumber: string,
    coId: String
) => {
    const headers = getHeaders();
    const res = await fetch(
        `${config.api}/etukarv2/audit/getCountAuditTrailByVehicle/${vehicleNumber}/${serviceId}/${coId}`,
        {
            headers,
        }
    );

    return res.json();
};

export const getListForFilterTransferId = async (
    pageLimit: number,
    pageNumber: number,
    serviceId: string,
    transferId: number,
    companyId: number
) => {
    const headers = getHeaders();
    try {
        const response = await timeoutPromise(
            3 * 60 * 1000,
            fetch(
                `${config.api}/etukarv2/audit/getListAuditTrailByTransferId`,
                {
                    method: "POST",
                    headers,
                    body: JSON.stringify({
                        pageLimit,
                        pageNumber,
                        serviceId,
                        transferId,
                        companyId,
                    }),
                }
            )
        );
        const res = response as Response;
        if (res !== null && res && res.ok) {
            return res.json();
        } else {
            throw "Unsuccessful response received";
        }
    } catch (error) {
        if (error instanceof Error && error.message.includes("timeout")) {
            throw ERROR_MESSAGE_TIMEOUT;
        } else {
            throw ERROR_MESSAGE_INTERNAL_SERVER_ERROR;
        }
    }
};

export const getCountForFilterTransferId = async (
    serviceId: string,
    transferId: string,
    coId: String
) => {
    const headers = getHeaders();
    const res = await fetch(
        `${config.api}/etukarv2/audit/getCountAuditTrailByTransferId/${transferId}/${serviceId}/${coId}`,
        {
            headers,
        }
    );

    return res.json();
};

export const transferVehicleInterBranch = async (
    inventoryIdList: [],
    newBranchId: number
) => {
    const headers = getHeaders();
    const res = await fetch(`${config.api}/inventory/transferToBranch`, {
        method: "POST",
        headers,
        body: JSON.stringify({ inventoryIdList, newBranchId }),
    });

    return res.json();
};

export const changeOwnPassword = async (
    currentPassword: string,
    myegAdmin: string,
    newPassword: string,
    nric: string
) => {
    const headers = getHeaders();
    const res = await fetch(`${config.api}/etukarv2/changePassword`, {
        method: "POST",
        headers,
        body: JSON.stringify({ currentPassword, myegAdmin, newPassword, nric }),
    });

    return res.json();
};

export const getDistrictList = async (
    stateCode: string
): Promise<GetDistrictListResponse> => {
    const headers = getHeaders();
    const res = await fetch(
        `${config.api}/etukarv2/getDistrictList/${stateCode}`,
        {
            headers,
        }
    );

    return verifyResponse(res);
};

export const updatePhoneNumber = (nric, phoneNo) => {
    const headers = getHeaders();
    return fetch(
        `${config.api}/etukarv2/updatePhoneNumber/${nric}/${phoneNo}`,
        {
            headers,
        }
    ).then((res) => res.json());
};

export const sendSmsOtp = (nric, smsProvider, newPhoneNumber) => {
    const headers = getHeaders();
    let url = `${config.api}/etukarv2/otp/sendOTP/${nric}/${smsProvider}/${uId}`;
    if (newPhoneNumber) {
        url += `?newPhoneNumber=${newPhoneNumber}`;
    }
    return fetch(url, {
        headers,
    }).then((res) => verifyResponse(res));
};

export const verifySmsOtp = (otp) => {
    const headers = getHeaders();
    return fetch(`${config.api}/etukarv2/otp/verifyOtp/${otp}/${uId}`, {
        headers,
    }).then((res) => verifyResponse(res));
};

export const verifyApproverSmsOtp = (nric, otp) => {
    const headers = getHeaders();
    return fetch(
        `${config.api}/etukarv2/otp/verifyApproverOtp/${nric}/${otp}/${uId}`,
        {
            headers,
        }
    ).then((res) => verifyResponse(res));
};

export const checkOtpStatus = () => {
    const headers = getHeaders();
    return fetch(`${config.api}/etukarv2/otp/checkOtpStatus`, {
        headers,
    }).then((res) => verifyResponse(res));
};

export const inUpdateVehicleDetails = (
    // carMake,
    // carModel,
    chassisNo,
    dealerCompanyROC,
    engineNo,
    // manufactureYear,
    transferId
    // vehicleType
) => {
    const headers = getHeaders();
    return fetch(`${config.api}/etukarv2/in/update-vehicle-details`, {
        method: "POST",
        headers,
        body: JSON.stringify({
            // carMake,
            // carModel,
            chassisNo,
            dealerCompanyROC,
            engineNo,
            // manufactureYear,
            transferId,
            // vehicleType
        }),
    }).then((res) => res.json());
};

export const outUpdateVehicleDetails = (
    // carMake,
    // carModel,
    chassisNo,
    dealerCompanyROC,
    engineNo,
    // manufactureYear,
    // tacNumber,
    transferId
    // vehicleType
) => {
    const headers = getHeaders();
    return fetch(`${config.api}/etukarv2/out/update-vehicle-details`, {
        method: "POST",
        headers,
        body: JSON.stringify({
            // carMake,
            // carModel,
            chassisNo,
            dealerCompanyROC,
            engineNo,
            // manufactureYear,
            // tacNumber,
            transferId,
            // vehicleType
        }),
    }).then((res) => res.json());
};

export const getOwnerDetailsForCompany = (coRegNo) => {
    const headers = getHeaders();
    return fetch(`${config.api}/etukarv2/getOwnerDetailsForCompany`, {
        method: "POST",
        headers,
        body: JSON.stringify({ coRegNo }),
    }).then((res) => res.json());
};

export const getInTxnForAdmin = (searchBy, value, status) => {
    const headers = getHeaders();
    return fetch(
        `${config.api}/etukarv2/in/get-transactions-for-admin/${searchBy}/${value}/${status}`,
        {
            headers,
        }
    ).then((res) => res.json());
};

export const getM2mTxnForAdmin = (vehicleNo) => {
    const headers = getHeaders();
    return fetch(
        `${config.api}/etukarv2/in/get-m2m-transactions-for-admin/${vehicleNo}`,
        {
            headers,
        }
    ).then((res) => res.json());
};

export const getOutTxnForAdmin = (vehicleNo) => {
    const headers = getHeaders();
    return fetch(
        `${config.api}/etukarv2/out/get-out-transactions-for-admin/${vehicleNo}`,
        {
            headers,
        }
    ).then((res) => res.json());
};

export const getI2iTxnForAdmin = (searchBy, value, status) => {
    const headers = getHeaders();
    return fetch(
        `${config.api}/etukarv2/out/get-i2i-transactions-for-admin/${searchBy}/${value}/${status}`,
        {
            headers,
        }
    ).then((res) => res.json());
};

export const getInTxnByComRocVechNoStatus = (
    companyRoc,
    vehicleNumber,
    status
) => {
    const headers = getHeaders();
    return fetch(
        `${config.api}/etukarv2/in/get-in-transactions/${companyRoc}/${vehicleNumber}/${status}`,
        {
            headers,
        }
    ).then((res) => res.json());
};

export const getAptTxnByComRocVechNoStatus = (
    companyRoc,
    vehicleNumber,
    status
) => {
    const headers = getHeaders();
    return fetch(
        `${config.api}/etukarv2/in/get-APT-transactions/${companyRoc}/${vehicleNumber}/${status}`,
        {
            headers,
        }
    ).then((res) => res.json());
};

export const getOutTxnByComRocVechNoStatus = (
    companyRoc,
    branchId,
    vehicleNumber,
    status
) => {
    const headers = getHeaders();
    return fetch(
        `${config.api}/etukarv2/out/get-out-transactions/${companyRoc}/${branchId}/${vehicleNumber}/${status}`,
        {
            headers,
        }
    ).then((res) => res.json());
};

export const getI2iTxnByComRocVechNoStatus = (
    companyRoc,
    branchId,
    vehicleNumber,
    status
) => {
    const headers = getHeaders();
    return fetch(
        `${config.api}/etukarv2/out/get-i2i-transactions/${companyRoc}/${branchId}/${vehicleNumber}/${status}`,
        {
            headers,
        }
    ).then((res) => res.json());
};

export const searchInTransactions = (
    branchId,
    dealerCompanyROC,
    pageLimit,
    pageNumber,
    search,
    searchParam,
    sort,
    status
) => {
    const headers = getHeaders();
    return fetch(`${config.api}/etukarv2/in/search-in-transactions`, {
        method: "POST",
        headers,
        body: JSON.stringify({
            branchId,
            dealerCompanyROC,
            pageLimit,
            pageNumber,
            search,
            searchParam,
            sort,
            status,
        }),
    }).then((res) => verifyResponse(res));
};

export const searchAptTransactions = (
    branchId,
    dealerCompanyROC,
    pageLimit,
    pageNumber,
    search,
    searchParam,
    sort,
    status
) => {
    const headers = getHeaders();
    return fetch(`${config.api}/etukarv2/in/search-apt-transactions`, {
        method: "POST",
        headers,
        body: JSON.stringify({
            branchId,
            dealerCompanyROC,
            pageLimit,
            pageNumber,
            search,
            searchParam,
            sort,
            status,
        }),
    }).then((res) => verifyResponse(res));
};

export const searchI2iTransactions = (
    branchId,
    dealerCompanyROC,
    pageLimit,
    pageNumber,
    search,
    searchParam,
    sort,
    status
) => {
    const headers = getHeaders();
    return fetch(`${config.api}/etukarv2/out/search-i2i-transactions`, {
        method: "POST",
        headers,
        body: JSON.stringify({
            branchId,
            dealerCompanyROC,
            pageLimit,
            pageNumber,
            search,
            searchParam,
            sort,
            status,
        }),
    }).then((res) => verifyResponse(res));
};

export const searchOutTransactions = (
    branchId,
    dealerCompanyROC,
    pageLimit,
    pageNumber,
    search,
    searchParam,
    sort,
    status
) => {
    const headers = getHeaders();
    return fetch(`${config.api}/etukarv2/out/search-out-transactions`, {
        method: "POST",
        headers,
        body: JSON.stringify({
            branchId,
            dealerCompanyROC,
            pageLimit,
            pageNumber,
            search,
            searchParam,
            sort,
            status,
        }),
    }).then((res) => verifyResponse(res));
};

export const getInTxnForAdminPost = (
    branchId,
    dealerCompanyROC,
    pageLimit,
    pageNumber,
    search,
    searchParam,
    sort,
    status
) => {
    const headers = getHeaders();
    return fetch(`${config.api}/etukarv2/in/get-transactions-for-admin`, {
        method: "POST",
        headers,
        body: JSON.stringify({
            branchId,
            dealerCompanyROC,
            pageLimit,
            pageNumber,
            search,
            searchParam,
            sort,
            status,
        }),
    }).then((res) => res.json());
};

export const getM2mTxnForAdminPost = (
    branchId,
    dealerCompanyROC,
    pageLimit,
    pageNumber,
    search,
    searchParam,
    sort,
    status
) => {
    const headers = getHeaders();
    return fetch(`${config.api}/etukarv2/in/get-m2m-transactions-for-admin`, {
        method: "POST",
        headers,
        body: JSON.stringify({
            branchId,
            dealerCompanyROC,
            pageLimit,
            pageNumber,
            search,
            searchParam,
            sort,
            status,
        }),
    }).then((res) => res.json());
};

export const getAptTxnForAdminPost = (
    branchId,
    dealerCompanyROC,
    pageLimit,
    pageNumber,
    search,
    searchParam,
    sort,
    status
) => {
    const headers = getHeaders();
    return fetch(`${config.api}/etukarv2/in/get-apt-transactions-for-admin`, {
        method: "POST",
        headers,
        body: JSON.stringify({
            branchId,
            dealerCompanyROC,
            pageLimit,
            pageNumber,
            search,
            searchParam,
            sort,
            status,
        }),
    }).then((res) => res.json());
};

export const getI2iTxnForAdminPost = (
    branchId,
    dealerCompanyROC,
    pageLimit,
    pageNumber,
    search,
    searchParam,
    sort,
    status
) => {
    const headers = getHeaders();
    return fetch(`${config.api}/etukarv2/out/get-i2i-transactions-for-admin`, {
        method: "POST",
        headers,
        body: JSON.stringify({
            branchId,
            dealerCompanyROC,
            pageLimit,
            pageNumber,
            search,
            searchParam,
            sort,
            status,
        }),
    }).then((res) => res.json());
};

export const getOutTxnForAdminPost = (
    branchId,
    dealerCompanyROC,
    pageLimit,
    pageNumber,
    search,
    searchParam,
    sort,
    status
) => {
    const headers = getHeaders();
    return fetch(`${config.api}/etukarv2/out/get-out-transactions-for-admin`, {
        method: "POST",
        headers,
        body: JSON.stringify({
            branchId,
            dealerCompanyROC,
            pageLimit,
            pageNumber,
            search,
            searchParam,
            sort,
            status,
        }),
    }).then((res) => res.json());
};

/* export const getVehicleDetailsVix = (vehicleNumber) => {
    const headers = getHeaders();
    return fetch(`${config.api}/etukarv2/getVehicleDetails/${vehicleNumber}`, {
        headers,
    }).then((res) => res.json());
}; */

export const getVehicleDetailsVix = (vehicleNumber, idNo) => {
    const headers = getHeaders();
    return fetch(
        `${config.api}/etukarv2/getVehicleDetails/${vehicleNumber}/${idNo}`,
        {
            headers,
        }
    ).then((res) => res.json());
};

export const revertStatus = (approverNRIC, transferId) => {
    const headers = getHeaders();
    return fetch(`${config.api}/etukarv2/out/revert-status`, {
        method: "POST",
        headers,
        body: JSON.stringify({
            approverNRIC,
            transferId,
            uId,
        }),
    }).then((res) => res.json());
};

export const removeUid = () => {
    const headers = getHeaders();
    return fetch(`${config.api}/etukarv2/removeUid`, {
        method: "POST",
        headers,
    }).then((res) => res.json());
};

export const sendNotification = (
    approverEmail,
    moduleType,
    transferId,
    transferIdList,
    bulkTransferId,
    isM2mTransaction
) => {
    const headers = getHeaders();
    const companyId = getCompanyId();
    return fetch(`${config.api}/etukarv2/notification/send-notification`, {
        method: "POST",
        headers,
        body: JSON.stringify({
            approverEmail,
            moduleType,
            transferId,
            transferIdList,
            bulkTransferId,
            companyId,
            isM2mTransaction,
        }),
    }).then((res) => verifyResponse(res));
};

export const checkTxnApprovalStatus = (transferId, moduleType) => {
    const headers = getHeaders();
    return fetch(
        `${config.api}/etukarv2/notification/check-transaction-approval-status/${transferId}/${moduleType}`,
        {
            headers,
        }
    ).then((res) => res.json());
};

async function verifyResponse(res) {
    let response = await res.json();
    if (response.message === "Invalid Token") {
        message.loading("Redirecting to login page...");
        await logout();
        await removeUserRole();
        window.location.pathname = "/login";
    } else {
        return response;
    }
}

function timeoutPromise(ms, promise) {
    return new Promise((resolve, reject) => {
        const timeoutId = setTimeout(() => {
            reject(new Error("Promise timeout"));
        }, ms);

        promise.then(
            (res) => {
                clearTimeout(timeoutId);
                resolve(res);
            },
            (err) => {
                clearTimeout(timeoutId);
                reject(err);
            }
        );
    });
}

export const searchM2mTransactions = (
    branchId,
    dealerCompanyROC,
    pageLimit,
    pageNumber,
    search,
    searchParam,
    sort,
    status
) => {
    const headers = getHeaders();
    return fetch(`${config.api}/etukarv2/in/search-m2m-transactions`, {
        method: "POST",
        headers,
        body: JSON.stringify({
            branchId,
            dealerCompanyROC,
            pageLimit,
            pageNumber,
            search,
            searchParam,
            sort,
            status,
        }),
    }).then((res) => verifyResponse(res));
};

export const m2mProceedBulk = async (
    buyerRequest: SaveProceedBulkRequest
): Promise<BulkM2MProceedResponse> => {
    const headers = getHeaders();
    buyerRequest.uId = uId;
    const res = await fetch(`${config.api}/etukarv2/in/save-bulk-m2m-proceed`, {
        method: "POST",
        headers,
        body: JSON.stringify({
            ...buyerRequest,
        }),
    });
    return await res.json();
};

export const ucdRegistration = async (
    ucdRequest: UcdRegistrationRequest
): Promise<UcdRegistrationResponse> => {
    const headers = getHeaders();
    const res = await fetch(`${config.api}/etukarv2/ucdRegistration`, {
        method: "POST",
        headers,
        body: JSON.stringify({
            ...ucdRequest,
        }),
    });
    return await res.json();
};
export const getPrepaidReceiptV2IN = (
    inV2GetPrepareReceiptRequest: InV2GetPrepareReceiptRequest
) => {
    const headers = getHeaders();
    return fetch(`${config.api}/prepaid/getV2ReceiptIN`, {
        method: "POST",
        headers,
        body: JSON.stringify(inV2GetPrepareReceiptRequest),
    })
        .then((response) => response.blob())
        .then((blob) => {
            const date = parseDate(moment.now());
            var url = window.URL.createObjectURL(blob);
            var a = document.createElement("a");
            a.href = url;
            a.download = `${inV2GetPrepareReceiptRequest.vehNo} ${date}.pdf`;
            document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
            a.click();
            a.remove(); //afterwards we remove the element again
        });
};

export const getPrepaidReceiptV2OUT = (companyId, transferId, vehNo, type) => {
    const headers = getHeaders();
    return fetch(`${config.api}/prepaid/getV2ReceiptOUT`, {
        method: "POST",
        headers,
        body: JSON.stringify({
            companyId,
            transferId,
            vehNo,
            type,
        }),
    })
        .then((response) => response.blob())
        .then((blob) => {
            const date = parseDate(moment.now());
            var url = window.URL.createObjectURL(blob);
            var a = document.createElement("a");
            a.href = url;
            a.download = `${vehNo} ${date}.pdf`;
            document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
            a.click();
            a.remove(); //afterwards we remove the element again
        });
};

export const getCityList = async (
    stateCode: string
): Promise<GetCityListResponse> => {
    const headers = getHeaders();
    const res = await fetch(`${config.api}/etukarv2/getCityList/${stateCode}`, {
        headers,
    });

    return verifyResponse(res);
};

export const validateCity = async (cityName: string): Promise<boolean> => {
    const headers = getHeaders();
    const res = await fetch(`${config.api}/etukarv2/validateCity/${cityName}`, {
        headers,
    });

    return verifyResponse(res);
};

export const validateCityWithRequestParam = async (
    cityName: string
): Promise<boolean> => {
    const headers = getHeaders();
    const res = await fetch(
        `${config.api}/etukarv2/validateCity?cityName=${cityName}`,
        {
            headers,
        }
    );

    return verifyResponse(res);
};

export const getAnnouncement = async (): Promise<Announcement> => {
    const headers = getHeaders();
    const res = await fetch(`${config.api}/etukarv2/getAnnouncement`, {
        headers,
    });

    return verifyResponse(res);
};

export const getUsersByCompanyId = (companyId) => {
    const headers = getHeaders();
    return fetch(`${config.api}/etukarv2/viewCompany/${companyId}/users`, {
        headers,
    }).then((res) => res.json());
};

export const encryptUrl = async (): Promise<EncryptUrlResponse> => {
    const headers = getHeaders();
    const res = await fetch(`${config.api}/etukarv2/encryptUrl`, {
        headers,
    });

    return verifyResponse(res);
};

export const decryptUrl = async (
    decryptUrlRequest: DecryptUrlRequest
): Promise<DecryptUrlResponse> => {
    const headers = getHeaders();
    const res = await fetch(`${config.api}/etukarv2/decryptUrl`, {
        method: "POST",
        headers,
        body: JSON.stringify({
            ...decryptUrlRequest,
        }),
    });
    return await res.json();
};

export const recoverExpiredTransaction = (transferId, moduleType) => {
    const headers = getHeaders();
    return fetch(`${config.api}/etukarv2/out/recover-expired-transaction`, {
        method: "POST",
        headers,
        body: JSON.stringify({
            transferId,
            moduleType,
            uId,
        }),
    }).then((res) => verifyResponse(res));
};

export const getV2ReceiptAPT = (companyId, transferId, vehNo, type) => {
    const headers = getHeaders();
    return fetch(`${config.api}/prepaid/getV2ReceiptAPT`, {
        method: "POST",
        headers,
        body: JSON.stringify({
            companyId,
            transferId,
            vehNo,
            type,
        }),
    })
        .then((response) => response.blob())
        .then((blob) => {
            const date = parseDate(moment.now());
            var url = window.URL.createObjectURL(blob);
            var a = document.createElement("a");
            a.href = url;
            a.download = `${vehNo} ${date}.pdf`;
            document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
            a.click();
            a.remove(); //afterwards we remove the element again
        });
};

export const checkBulkTransactionDetails = (bulkTransferId) => {
    const headers = getHeaders();
    return fetch(
        `${config.api}/etukarv2/in/check-bulk-transaction-details/${bulkTransferId}`,
        {
            headers,
        }
    ).then((res) => res.json());
};

export const loginNric = (nric, password) => {
    return fetch(`${config.api}/etukarv2/login-nric`, {
        method: "POST",
        headers: getHeadersWOToken(),
        body: JSON.stringify({
            nric,
            password,
        }),
    }).then((res) => res.json());
};

export const forgotPasswordNric = async (nric) => {
    const res = await fetch(
        `${config.api}/etukarv2/forgotPassword/nric/${nric}`,
        {
            method: "POST",
            headers: getHeadersWOToken(),
            body: JSON.stringify({
                nric,
            }),
        }
    );
    return await res.json();
};

export const check1midAccount = (nric, password) => {
    const headers = getHeaders();
    return fetch(`${config.api}/etukarv2/check1midAccount`, {
        method: "POST",
        headers,
        body: JSON.stringify({
            nric,
            password,
        }),
    }).then((res) => res.json());
};

export const saveInventory = (
    companyId,
    branchId,
    vehicleNo,
    chassisNo,
    engineNo,
    thumbInUser,
    transactionType,
    transactionStatus
) => {
    const headers = getHeaders();
    return fetch(`${config.api}/inventory/save`, {
        method: "POST",
        headers,
        body: JSON.stringify({
            companyId,
            branchId,
            vehicleNo,
            chassisNo,
            engineNo,
            thumbInUser,
            transactionType,
            transactionStatus,
        }),
    }).then((res) => res.json());
};

export const editInventory = (
    id,
    companyId,
    branchId,
    vehicleNo,
    chassisNo,
    engineNo
) => {
    const headers = getHeaders();
    return fetch(`${config.api}/inventory/update`, {
        method: "POST",
        headers,
        body: JSON.stringify({
            id,
            companyId,
            branchId,
            vehicleNo,
            chassisNo,
            engineNo,
        }),
    }).then((res) => res.json());
};

export const bulkM2mBuyer = async (
    buyerRequest: SaveBulkBuyerRequest
): Promise<BulkM2MBuyerResponse> => {
    const headers = getHeaders();
    buyerRequest.uId = uId;
    const res = await fetch(`${config.api}/etukarv2/in/save-bulk-m2m-buyer`, {
        method: "POST",
        headers,
        body: JSON.stringify({
            ...buyerRequest,
        }),
    });
    return await res.json();
};

export const getInBulkTransactionDetails = async (
    bulkTransferId,
    moduleType
): Promise<BulkTransactionDetailResponse> => {
    const headers = getHeaders();
    return fetch(`${config.api}/etukarv2/in/get-bulk-transaction-details`, {
        method: "POST",
        headers,
        body: JSON.stringify({
            bulkTransferId,
            moduleType,
            uId,
        }),
    }).then((res) => verifyResponse(res));
};

export const InBulkTransferInquiryWithJPJ = async (
    request: SaveProceedBulkRequest
): Promise<BulkM2MProceedResponse> => {
    const headers = getHeaders();
    request.uId = uId;
    const res = await fetch(`${config.api}/etukarv2/in/bulk-inquiry-with-jpj`, {
        method: "POST",
        headers,
        body: JSON.stringify({
            ...request,
        }),
    });
    return await res.json();
};

export const bulkProcessPaymentIN = (
    request: bulkPaymentRequest
): Promise<bulkPaymentResponse> => {
    const headers = getHeaders();
    request.uId = uId;
    return fetch(`${config.api}/prepaid/bulkProcessPaymentIN`, {
        method: "POST",
        headers,
        body: JSON.stringify(request),
    }).then((res) => verifyResponse(res));
};

export const OUTBulkTransferInquiryWithJPJ = async (
    request: SaveProceedBulkRequest
): Promise<BulkM2MProceedResponse> => {
    const headers = getHeaders();
    request.uId = uId;
    const res = await fetch(
        `${config.api}/etukarv2/out/bulk-seller-buyer-inquiry`,
        {
            method: "POST",
            headers,
            body: JSON.stringify({
                ...request,
            }),
        }
    );
    return await res.json();
};

export const checkOutBulkTransactionDetails = (bulkTransferId) => {
    const headers = getHeaders();
    return fetch(
        `${config.api}/etukarv2/out/check-bulk-transaction-details/${bulkTransferId}`,
        {
            headers,
        }
    ).then((res) => res.json());
};

export const bulkProcessPaymentOUT = (
    request: bulkPaymentRequest
): Promise<bulkPaymentResponse> => {
    const headers = getHeaders();
    request.uId = uId;
    return fetch(`${config.api}/prepaid/bulkProcessPaymentOUT`, {
        method: "POST",
        headers,
        body: JSON.stringify(request),
    }).then((res) => verifyResponse(res));
};

const getRole = {
    message: "Success",
    status: true,
    roles: [
        {
            name: "OWNER",
        },
    ],
    permissions: {
        //IN
        inTransaction: true,
        inSelectApproval: true,
        inSelfApproval: true,
        inPayment: true,
        //OUT
        outTransaction: true,
        outSelectApproval: true,
        outSelfApproval: true,
        outPayment: true,
        //M2M
        m2mTransaction: true,
        m2mSelectApproval: true,
        m2mSelfApproval: true,
        m2mPayment: true,
        //I2I
        i2iTransaction: true,
        i2iSelectApproval: true,
        i2iSelfApproval: true,
        i2iPayment: true,
        //Account management
        addNewUser: true,
        editUserDetails: true,
        viewBalance: true,
        topupBalance: true,
        addBranch: true,
        editBranch: true,
        interBranchTransfer: true,
        //Report
        paymentReport: true,
        interBranchReport: true,
        auditReport: true,
        vehicleMovementReport: true,
        transactionReport: true,
        inventoryReport: true,
    },
};
export const getRolesPermissions = (nric, companyId) => {
    // return Promise.resolve( getRole );
    const headers = getHeaders();
    return fetch(
        `${config.api}/etukarv2/getRolesPermission/${nric}/${companyId}`,
        {
            headers,
        }
    ).then((res) => verifyResponse(res));
};

const getPermissionMessage = {
    status: true,
    message: "Success",
    roles: [
        {
            roleName: "VALUER",
            roleMessage: "",
        },
        {
            roleName: "SALES",
            roleMessage: "",
        },
        {
            roleName: "ADMIN",
            roleMessage:
                "Perform IN & M2M transaction<br/>Able to make IN payment only",
        },
        {
            roleName: "SALES_MANAGER",
            roleMessage:
                "Perform IN & M2M transaction<br/>Able to make IN payment only<br/>Able to add users and branches<br/>Able to perform inter-branch transfer",
        },
        {
            roleName: "GENERAL_MANAGER",
            roleMessage:
                "Perform IN & M2M transaction<br/>Able to make all payment",
        },
    ],
};

export const getRolesMessage = (companyId) => {
    // return Promise.resolve( getPermissionMessage );
    const headers = getHeaders();
    return fetch(`${config.api}/etukarv2/getRolesMessage/${companyId}`, {
        headers,
    }).then((res) => verifyResponse(res));
};

export const sendVerificationCode = ({
    userFullName,
    oldEmail,
    oldPassword,
    newEmail,
    newPassword,
    isMyegAdmin,
}) => {
    const headers = getHeaders();
    return fetch(`${config.api}/etukarv2/sendVerificationCode`, {
        headers,
        method: "POST",
        body: JSON.stringify({
            userFullName,
            oldEmail,
            oldPassword,
            newEmail,
            newPassword,
            isMyegAdmin,
        }),
    }).then((res) => verifyResponse(res));
};

export const updateEmailAndPassword = ({
    userFullName,
    currentEmail,
    newEmail,
    currentPassword,
    newPassword,
    isMyegAdmin,
    verificationCode,
    nric,
}) => {
    const headers = getHeaders();
    return fetch(`${config.api}/etukarv2/updateEmailAndPassword`, {
        headers,
        method: "POST",
        body: JSON.stringify({
            userFullName,
            currentEmail,
            newEmail,
            currentPassword,
            newPassword,
            isMyegAdmin,
            verificationCode,
            nric,
        }),
    }).then((res) => verifyResponse(res));
};

export const saveBulkOutSeller = (
    branchId,
    currentLoginUserName,
    dealerCompanyROC,
    isOutTxn,
    sellerInfo,
    transactionType,
    vehicleInfoList
) => {
    const headers = getHeaders();
    return fetch(`${config.api}/etukarv2/out/save-bulk-seller`, {
        method: "POST",
        headers,
        body: JSON.stringify({
            branchId,
            currentLoginUserName,
            dealerCompanyROC,
            isOutTxn,
            sellerInfo,
            transactionType,
            vehicleInfoList,
            uId,
        }),
    }).then((res) => verifyResponse(res));
};

export const saveBulkOutBuyer = (
    buyerInfo,
    currentLoginUserName,
    dealerCompanyROC,
    verifiedBuyerNric,
    bulkTransferId
) => {
    const headers = getHeaders();
    const dealerCompanyID = getCompanyId();
    return fetch(`${config.api}/etukarv2/out/save-bulk-buyer`, {
        method: "POST",
        headers,
        body: JSON.stringify({
            buyerInfo,
            currentLoginUserName,
            dealerCompanyROC,
            verifiedBuyerNric,
            uId,
            dealerCompanyID,
            bulkTransferId,
        }),
    }).then((res) => verifyResponse(res));
};

export const getOutBulkTransactionDetails = async (
    bulkTransferId,
    moduleType
): Promise<BulkTransactionDetailResponse> => {
    const headers = getHeaders();
    return fetch(`${config.api}/etukarv2/out/get-bulk-transaction-details`, {
        method: "POST",
        headers,
        body: JSON.stringify({
            bulkTransferId,
            moduleType,
            uId,
        }),
    }).then((res) => verifyResponse(res));
};

export const checkPhoneNumberStaff = ({ phone, nric }) => {
    const headers = getHeaders();
    return fetch(
        `${config.api}/etukarv2/checkPhoneNumberStaff?phone=${phone}&nric=${nric}`,
        {
            headers,
            method: "GET",
        }
    ).then((res) => verifyResponse(res));
};

export const checkPhoneNumberLogin = () => {
    const headers = getHeaders();
    return fetch(`${config.api}/etukarv2/checkPhoneNumberLogin`, {
        headers,
        method: "GET",
    }).then((res) => verifyResponse(res));
};

function getCmsHeaders() {
    const AUTH_TOKEN = getToken();
    return {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${process.env.REACT_APP_CMS_TOKEN}`,
    };
}

export const searchAptTransactionsReport = (
    branchId: string,
    dealerCompanyROC: string,
    pageLimit: number,
    pageNumber: number,
    status: string,
    search: string,
    searchParam: string,
    sort: string
): Promise<searchAptTransactionsResponse> => {
    const headers = getHeaders();
    return fetch(`${config.api}/etukarv2/in/search-apt-report-transactions`, {
        method: "POST",
        headers,
        body: JSON.stringify({
            branchId,
            dealerCompanyROC,
            pageLimit,
            pageNumber,
            status,
            search,
            searchParam,
            sort,
        }),
    }).then((res) => res.json());
};

export const getCmsNotification = () => {
    const headers = getCmsHeaders();
    return fetch(`${config.cmsApi}/api/twocar-notification`, {
        headers,
    }).then((res) => verifyResponse(res));
};

export const getUserActivationRole = () => {
    const headers = getHeaders();
    return fetch(`${config.api}/etukarv2/getUserActivationRole`, {
        headers,
    }).then((res) => verifyResponse(res));
};

export const updateUnverifiedUserNric = ({ currentNric, newNric }) => {
    const headers = getHeaders();
    return fetch(`${config.api}/etukarv2/updateUnverifiedUserNric`, {
        headers,
        method: "POST",
        body: JSON.stringify({
            currentNric,
            newNric,
        }),
    }).then((res) => verifyResponse(res));
};

export const verifyUserAuditTrail = ({
    nric,
    password,
    action,
    currentValue,
    newValue,
    remarks,
}) => {
    const headers = getHeaders();
    return fetch(`${config.api}/etukarv2/verifyUserAuditTrail`, {
        headers,
        method: "POST",
        body: JSON.stringify({
            nric,
            password,
            action,
            currentValue,
            newValue,
            remarks,
        }),
    }).then((res) => verifyResponse(res));
};

export const contactUsRedmineGetTrackers = () => {
    const headers = getHeadersWOToken();
    return fetch(`${config.api}/etukarv2/contact-us/redmine/get-trackers`, {
        headers,
    }).then((res) => res.json());
};

export const contactUsRedmineCreateIssue = ({
    trackerId,
    subject,
    subCategory,
    description,
    phoneNo,
    rocNumber,
    loginName,
    numberOfDevice,
    deviceType,
}) => {
    const headers = getHeadersWOToken();
    return fetch(`${config.api}/etukarv2/contact-us/redmine/create-issue`, {
        method: "POST",
        headers,
        body: JSON.stringify({
            trackerId,
            subject,
            subCategory,
            description,
            phoneNo,
            rocNumber,
            loginName,
            numberOfDevice,
            deviceType,
        }),
    }).then((res) => res.json());
};

export const contactUsRedmineGetUserInfo = (nric) => {
    const headers = getHeadersWOToken();
    return fetch(
        `${config.api}/etukarv2/contact-us/redmine/get-user-info/${nric}`,
        {
            headers,
        }
    ).then((res) => res.json());
};

export const contactUsRedmineSendOtpByEmail = ({ userName, nric }) => {
    const headers = getHeadersWOToken();
    return fetch(
        `${config.api}/etukarv2/contact-us/redmine/sendOtpByEmail?username=${userName}&nric=${nric}`,
        {
            method: "POST",
            headers,
        }
    ).then((res) => res.json());
};

export const contactUsRedmineVerifyOtpByEmail = ({ userName, emailOtp }) => {
    const headers = getHeadersWOToken();
    return fetch(
        `${config.api}/etukarv2/contact-us/redmine/verifyOtpByEmail?username=${userName}&otp=${emailOtp}`,
        {
            method: "POST",
            headers,
        }
    ).then((res) => res.json());
};

export const getRemoteUserInfo = ({ encryptedString, thumbprintNric = "" }) => {
    const headers = getHeadersWOToken();
    return fetch(`${config.api}/etukarv2/getRemoteUserInfo`, {
        method: "POST",
        headers,
        body: JSON.stringify({
            encryptedString,
            thumbprintNric,
        }),
    }).then((res) => res.json());
};

export const postVerifyRecaptcha = ({ secretKey, response }) => {
    const headers = getHeadersWOToken();
    return fetch(`${config.api}/etukarv2/verifyRecaptcha`, {
        method: "POST",
        headers,
        body: JSON.stringify({
            secret: secretKey,
            response,
        }),
    }).then((res) => res.json());
};

export const getTempUser = (id) => {
    const headers = getHeaders();
    return fetch(`${config.api}/etukarv2/viewTempUser/${id}`, {
        headers,
    }).then((res) => res.json());
};

/* export const updateTempBranchUser = async (
    branchId: number,
    coId: number,
    contactNumber: string,
    email: string,
    nricNo: string,
    personId: number,
    personName: string,
    roles: string[]
) => {
    const headers = getHeaders();
    const res = await fetch(`${config.api}/etukarv2/updateTempUser`, {
        method: "POST",
        headers,
        body: JSON.stringify({
            branchId,
            coId,
            contactNumber,
            email,
            nricNo,
            personId,
            personName,
            roles,
        }),
    });
    return await res.json();
}; */

export const encryptUrlForRemote = async (
    token: string
): Promise<EncryptUrlResponse> => {
    const res = await fetch(`${config.api}/etukarv2/encryptUrlForRemote`, {
        method: "POST",
        headers: getHeadersWOToken(),
        body: JSON.stringify({
            token,
        }),
    });

    return res.json();
};

export const decryptUrlForRemote = async (
    decryptUrlRequest: DecryptUrlRequest
): Promise<DecryptUrlResponse> => {
    const res = await fetch(`${config.api}/etukarv2/decryptUrlForRemote`, {
        method: "POST",
        // headers,
        headers: getHeadersWOToken(),
        body: JSON.stringify({
            ...decryptUrlRequest,
        }),
    });
    return res.json();
};

export const ActivateRemoteUser = async (
    request: ActivateRemoteUserRequest
): Promise<Create1MIDAccountResponse> => {
    const res = await fetch(`${config.api}/etukarv2/activateRemoteUser`, {
        method: "POST",
        // headers,
        headers: getHeadersWOToken(),
        body: JSON.stringify({
            ...request,
        }),
    });
    return res.json();
};

export const getMonthlyCarTransactions = (companyId) => {
    const headers = getHeaders();
    return fetch(`${config.api}/etukarv2/in/get-monthly-car-transactions/${companyId}`, {
        headers,
        method: "GET",
    }).then((res) => verifyResponse(res));
};
