import React, { useContext, useEffect, useState } from "react";
import {
	Form,
	Input,
	Button,
	Table,
	message,
	Select,
	Switch,
	// Modal,
	Row,
	Col,
	Image,
	Breadcrumb,
	Flex,
} from "antd";
import { PageHeader } from "@ant-design/pro-components";
import { useHistory } from "react-router-dom";
import { HQ_BRANCH } from "../../constants";
import "./index.less";
import {
    // deleteBranchuser,
    getUsersByCompanyId,
    getUserActivationRole,
    activateOrDeactivateBranchUser,
} from "../../services/api";
import { SessionContext } from "../../App";
import { STEPS } from "../BranchUserFormPage/steps";
import arrow_back from "../../assets/arrow_back.svg";
import {
	getIsAuthorizeToAccessUserManagement,
	setIsAuthorizeToAccessUserManagement,
	getUserRole,
	getPermissions
} from '../../services/local';
import ConfirmActivateOrDeactivateUserComponent from "../../components/component/Layout/ConfirmActivateOrDeactivateUserComponent";
import export_xls_filed from "../../assets/export_xls_filled.svg";
import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;


export default function BranchUsersPage() {
    const {
        users: _users,
        company,
        branch,
        branchs,
    } = useContext(SessionContext);

    const history = useHistory();
    const [isLoading, setIsLoading] = useState(true);
    const [searchBranchId, setSearchBranchId] = useState("All");
    // const [selectedIds, setSelectedIds] = useState([]);
    const [users, setUsers] = useState([]);
    const [branches, setBranches] = useState([]);
    const [searchForm] = Form.useForm();
    const [dataSource, setDataSource] = useState([]);
    const [dealerRoleLevel, setDealerRoleLevel] = useState(0);
    const [confirmActivateDeactivateModal, setConfirmActivateDeactivateModal] =
        useState({
            show: false,
            type: "",
            id: "",
            tempAuthPersonnel: false,
        });

    const [permissions, setPermissions] = useState([]);
    useEffect(async () => {
        if (company.id) {
            await getPermissions().then(async (permissions) => {
                setPermissions(permissions);
            });
        }
    }, [company]);

    const getInitialData = async () => {
        if (!company?.id || parseInt(branch?.id) >= 0 == false) return null;

        setIsLoading(true);

        setBranches(branchs || []);

        try {
            const [users, useractivationRole] = await Promise.all([
                getUsersByCompanyId(company.id),
                getUserActivationRole(),
            ]);
            if (users.status !== "00") throw users.message;
            setUsers(users?.users || []);

            setDealerRoleLevel(useractivationRole?.dealerRoleLevel || 0);

            message.success(`${users?.users?.length || 0}  users found`);
        } catch (error) {
            message.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        const canAccessUsermanagement = getIsAuthorizeToAccessUserManagement();

        if (canAccessUsermanagement === "true") {
            getInitialData();
        } else if (canAccessUsermanagement === "false") {
            history.goBack();
        }
    }, [branch, company]);

    useEffect(() => {
        setDataSource(
            users.map((user) => {
                return {
                    key: user.id,
                    ...user,
                };
            })
        );
    }, [users]);

    // const onDelete = () => {
    //     Modal.confirm({
    //         title: `Confirm delete ${selectedIds.length} users?`,
    //         onOk: async () => {
    //             setIsLoading(true);
    //             const promises = selectedIds
    //                 .filter((id) => id)
    //                 .map((id) => deleteBranchuser(id));

    //             await Promise.allSettled(promises)
    //                 .then((results) => {
    //                     message.success(`Delete user(s)`);
    //                     setSelectedIds([]);
    //                     getInitialData();
    //                 })
    //                 .catch((err) => {
    //                     err && message.error(err.toString());
    //                 })
    //                 .finally(() => {
    //                     setIsLoading(false);
    //                 });
    //         },
    //     });
    // };

    const columns = [
        {
			title: "Name",
			dataIndex: "personName",
			sorter: (a, b) => a.personName.localeCompare(b.personName),
		},
		{
			title: "Company Branch",
			dataIndex: "branchId",
			ellipsis: true,
			width: "12%",
			sorter: (firstBranch, secondBranch) =>
				[...branches, HQ_BRANCH]
					.find((a) => a.id === firstBranch.branchId)
					?.branchName.localeCompare(
						[...branches, HQ_BRANCH].find(
							(b) => b.id === secondBranch.branchId
						)?.branchName
					),
			render: (branchId) => {
				return (
					[...branches, HQ_BRANCH].find((b) => b.id === branchId)
						?.branchName || `(No branch found)`
				);
			},
		},
        {
			title: "Email",
			dataIndex: "email",
			width: "20%",
			sorter: (a, b) =>  a.email.localeCompare(b.email),
		},
        {
			title: "Contact Number",
			dataIndex: "contactNumber",
			width: "12%",
			sorter: (a, b) => Number(b.contactNumber) - Number(a.contactNumber),
		},
		{
			title: "Status",
			dataIndex: "is_active",
			width: "10%",
			sorter: (a, b) => Number(b.is_active) - Number(a.is_active),
			render: (is_active) => {
				return is_active ? "Active" : "Inactive";
			},
		},
        {
            title: "Activate",
            sorter: (a, b) => Number(a.is_active) - Number(b.is_active),
            render: (rowData) => {
                return (
                    <>
                        <Switch
                            checked={rowData.is_active}
                            disabled={disabledActivateReactivate(
                                rowData.is_active
                            )}
                            onChange={(e) => {
                                const is_active = e;

                                setIsLoading(true);
                                checkUserRole(rowData, is_active);
                            }}
                        />
                    </>
                );
            },
        },
        {
            title: "Action",
            fixed: "right",
            render: (rowData) => {
                if (rowData.is_active !== true) return;
                if (!permissions.editUserDetails) return;
                return (
                    <Button
                        style={{
                            border: "1px solid #2B61C4",
                            padding: "12px 24px",
                            borderRadius: "4px",
                            color: "#2B61C4",
                            display: "flex",
                            alignItems: "center",
                            font: "normal normal 600 14px/19px Open Sans",
                        }}
                        onClick={() => {
                            history.push(`/usermanagement/edit`, {
                                subStep:
                                    STEPS.AUTHORIZE_BRANCH_USER.subSteps.FORM,
                                user: rowData,
                                dealerRoleLevel: dealerRoleLevel || 0,
                            });
                        }}
                    >
                        EDIT{" "}
                    </Button>
                );
            },
        },
    ];

    const onSearch = () => {
        // get the searchBy and search term user input from the form state through useForm hook
        const { searchBy, searchTerm } = searchForm.getFieldsValue();

        // filter the user data source based on the searchBy and searchTerm
        const filteredUsers = users
            .filter((user) => {
                if (searchBy !== "branchId") {
                    return user[searchBy]
                        .toString()
                        .toLowerCase()
                        .includes(searchTerm.toString().toLowerCase());
                } else {
                    return [...branches, HQ_BRANCH]
                        .find((b) => b.id === user.branchId)
                        ?.branchName.toLowerCase()
                        .includes(searchTerm.toString().toLowerCase());
                }
            })
            .map((user) => {
                return {
                    key: user.id,
                    ...user,
                };
            });

        // alter and re-render the table based on the filtered result of filtering /searching
        setDataSource(filteredUsers);
    };

    const activateDeactivateRoleMapper = [
        // https://jira.myeg.com.my/browse/ETK-877
        // {
        //     dealerRoleLevel: userRoleLevel,
        //     activate: able to activate or not,
        //     deactivate: able to deqactivate or not,
        //     activateReason: need reason to activate or not,
        //     deactivateReason: need reason to deactivate or not,
        // },
        {
            dealerRoleLevel: 6,
            activate: true,
            deactivate: true,
            activateReason: true,
            deactivateReason: false,
        },
        {
            dealerRoleLevel: 5,
            activate: true,
            deactivate: true,
            activateReason: true,
            deactivateReason: false,
        },
        {
            dealerRoleLevel: 4,
            activate: false,
            deactivate: true,
            deactivateReason: true,
        },
        {
            dealerRoleLevel: 3,
            activate: false,
            deactivate: true,
            deactivateReason: true,
        },
        {
            dealerRoleLevel: 2,
            activate: false,
            deactivate: false,
        },
        {
            dealerRoleLevel: 1,
            activate: false,
            deactivate: false,
        },
    ];

    const matchingRole = activateDeactivateRoleMapper.find(
        (role) => role.dealerRoleLevel === dealerRoleLevel
    );
    const disabledActivateReactivate = (currentState) => {
        if (matchingRole) {
            return currentState
                ? !matchingRole.deactivate
                : !matchingRole.activate;
        } else {
            return false;
        }
    };

    const checkUserRole = (rowData, is_active) => {
        if (matchingRole) {
            if (is_active && matchingRole.activateReason) {
                setConfirmActivateDeactivateModal({
                    show: true,
                    type: "activate",
                    rowData,
                });
            } else if (!is_active && matchingRole.deactivateReason) {
                setConfirmActivateDeactivateModal({
                    show: true,
                    type: "deactivate",
                    rowData,
                });
            } else {
                // No need reason to activate/deactivate
                processActivateDeactivateUser(
                    rowData?.id,
                    is_active ? true : false,
                    "",
                    rowData?.tempAuthPersonnel,
                );
            }
        } else {
            return;
        }
    };

    const processActivateDeactivateUser = async (id, type, remark, tempAuthPersonnel) => {
        try {
            const response = await activateOrDeactivateBranchUser(
                type,
                id,
                company?.coRegNo,
                remark,
                tempAuthPersonnel
            );

            if (response && response?.status && id) {
                const newBranchUsers = dataSource.map((b) => {
                    if (b.id === id) {
                        b.is_active = type;
                    }
                    return b;
                });
                // message.success(res.message);
                setDataSource(newBranchUsers);
            }
        } catch (error) {
            message.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            <div className="page-content">
                <Breadcrumb separator=">" style={{ marginTop: "60px" }}>
                    <Breadcrumb.Item href="/">Dashboard</Breadcrumb.Item>
                    <Breadcrumb.Item href="/usermanagement">
                        User management
                    </Breadcrumb.Item>
                </Breadcrumb>
                <PageHeader
                    className="site-page-header"
                    style={{ paddingLeft: "0px" }}
                    title="User Management"
                />
                <Row>
                    <Col span={24}>
                        <div
                            style={{
                                font: "italic normal normal 16px/22px Open Sans",
                                color: "white",
                                marginBottom: "7px",
                            }}
                        >
                            Search by
                        </div>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                marginBottom: "25px",
                            }}
                        >
                            <Form
                                layout="inline"
                                className="select-search-wrapper"
                                style={{ borderRadius: 4 }}
                                initialValues={{
                                    searchBy: "personName",
                                }}
                                form={searchForm}
                            >
                                <Form.Item
                                    className="form-item"
                                    name={"searchBy"}
                                >
                                    <Select
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.children
                                                .toLowerCase()
                                                .indexOf(input.toLowerCase()) >=
                                            0
                                        }
                                        value={searchBranchId}
                                        style={{ width: "100%", maxWidth: 200 }}
                                    >
                                        <Select.Option value={"personName"}>
                                            Name
                                        </Select.Option>
                                        <Select.Option value={"branchId"}>
                                            Company Name
                                        </Select.Option>
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    className="form-item"
                                    name={"searchTerm"}
                                >
                                    <Input.Search
                                        style={{ maxWidth: "100%" }}
                                        size="large"
                                        placeholder={"Search"}
                                        enterButton
                                        onSearch={onSearch}
                                    />
                                </Form.Item>
                            </Form>

                            <Flex gap={'small'}>
								<ExcelFile
									element={
										<Button
											type="primary"
											disabled={dataSource.length === 0}
										>
											<Image
												src={export_xls_filed}
												preview={false}
												width={30}
												height={30}
												style={{ paddingRight: 10 }}
											/>
											Export Report
										</Button>
									}
									filename="Branch Users">
										<ExcelSheet data={dataSource} name="Branch Users">
											<ExcelColumn label="Name" value="personName" />
											<ExcelColumn label="Company Branch" value={(col) => {
												return (
													[...branches, HQ_BRANCH].find((b) => b.id === col?.branchId)
														?.branchName || `(No branch found)`
												);
											}} />
                                            <ExcelColumn label="Email" value="email" />
                                            <ExcelColumn label="Contact Number" value="contactNumber" />
											<ExcelColumn label="Status" value={(col) => {
												return col?.is_active ? "Active" : "Inactive";
											}} />
										</ExcelSheet>
								</ExcelFile>

								{permissions.addNewUser && 
								<Button
									className="add-new-user-button"
									type="primary"
									onClick={() => window.location.pathname = "/usermanagement/create"}
								>
									Add Staff
								</Button>}
							</Flex>
                        </div>
                    </Col>
                </Row>
                <Table
                    className="report-table"
                    size="small"
                    columns={columns}
                    dataSource={dataSource}
                    loading={isLoading}
                    scroll={{
                        x: columns.length * 150,
                    }}
                    // rowSelection={{
                    // 	type: "checkbox",
                    // 	onChange: (selectedRowKeys) => {
                    // 		setSelectedIds(selectedRowKeys);
                    // 	},
                    // }}
                    pagination={{
                        showSizeChanger: false,
                        showQuickJumper: false,
                    }}
                    footer={() => {
                        // return (
                        // 	selectedIds.length > 0 && (
                        // 		<Button
                        // 			className="delete-button buttons"
                        // 			icon={
                        // 				<CloseOutlined
                        // 					style={{ fontSize: 16 }}
                        // 				/>
                        // 			}
                        // 			shape="round"
                        // 			disabled={!selectedIds.length}
                        // 			onClick={onDelete}
                        // 			type="danger"
                        // 		>
                        // 			Delete Accounts
                        // 		</Button>
                        // 	)
                        // );
                    }}
                />
                <Button
                    className="back-button buttons"
                    shape="round"
                    onClick={() => {
                        history.push("/");
                    }}
                >
                    <Image src={arrow_back} preview={false} />
                    BACK
                </Button>
            </div>

            <ConfirmActivateOrDeactivateUserComponent
                modalInfo={confirmActivateDeactivateModal || {}}
                onClose={(id, type, remark, tempAuthPersonnel) => {
                    setConfirmActivateDeactivateModal({
                        show: false,
                        type: "",
                        id: "",
                        tempAuthPersonnel: false
                    });

                    processActivateDeactivateUser(id, type, remark, tempAuthPersonnel);
                }}
            />

            <style>
                {`                                
                .ant-select  {
                    max-width: 100% !important;
                }
                
                .ant-select > .ant-select-selector {
                    height: 48px !important;
                    display: flex !important;
                    align-items: center !important;
                }
            `}
            </style>
        </>
    );
}
