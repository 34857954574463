import { message, Modal } from "antd";
import {
  getDistrictList,
  saveOutSeller,
  isAccountExist,
  create1MIDAccount,
  saveOutBuyer,
  saveBulkOutBuyer,
  saveBulkOutSeller,
  OUTBulkTransferInquiryWithJPJ,
  saveOutSellerBuyerInquiry,
  processPaymentOut,
  outUpdateVehicleDetailsToJPJ,
  bulkProcessPaymentOUT,
} from "../../services/api";
import { breakdownIc } from "../../util";
import moment from "moment";
import {
  OUT_TRANSACTION_TYPES_IDS,
  TRANSACTION_OUT_RESPONSE_CODES,
  TRANSACTION_RESPONSE_CODES,
} from "../../constants";

const NOTIFICAION_APPROVAL_TYPE = {
  BULK_APPROVAL: "APPROVAL",
  BULK_IN_PAYMENT: "IN_PAYMENT",
  BULK_OUT_PAYMENT: "OUT_PAYMENT",
  BULK_I2I_PAYMENT: "I2I_PAYMENT",
  BULK_OUT_SUBMISSION: "OUT_SUBMISSION",
  BULK_I2I_SUBMISSION: "I2I_SUBMISSION",
};

export const saveSellerBuyerFunc = async (
  branch,
  user,
  company,
  props,
  vehicleData,
  setLoading,
  isBulk
) => {
  try {
    setLoading(true);
    if (!props?.buyer?.state) {
      message.error("State is missing.");
      return;
    }

    let buyerDistrictList = await getDistrictList(props?.buyer?.state);
    if (!buyerDistrictList) {
      message.error("District list is empty.");
      return;
    }

    await validateAndCreateAccountFunc(props);
    if (isBulk) {
      let bulkTransferId = null;
      const saveSellerRes = await saveBulkOutSellerFunc(
        branch,
        user,
        company,
        props,
        vehicleData
      )
        .then((saveOutSellerRes) => {
          if (saveOutSellerRes.saveSellerStatus !== "00") {
            throw saveOutSellerRes.saveSellerMSG ?? saveOutSellerRes.message;
          }
          bulkTransferId = saveOutSellerRes.bulkTransferId;
        })
        .catch((ex) => {
          message.error(ex);
          setLoading(false);
          return null;
        });

      if (saveSellerRes === null) return null;

      const saveBuyerRes = await saveBulkOutBuyerFunc(
        props,
        buyerDistrictList,
        user,
        company,
        bulkTransferId
      )
        .then((saveOutBuyerRes) => {
          if (saveOutBuyerRes.saveBuyerStatus !== "00")
            throw saveOutBuyerRes.saveBuyerMSG;

          message.success("Buyer information saved.");
          return saveOutBuyerRes.bulkTransferId;
        })
        .catch((ex) => {
          message.error(ex);
          setLoading(false);
          return null;
        });
      return saveBuyerRes;
    } else {
      let transferId = null;
      const saveSellerRes = await saveOutSellerFunc(
        branch,
        user,
        company,
        props,
        vehicleData
      )
        .then((saveOutSellerRes) => {
          // console.log("saveOutSellerRes", saveOutSellerRes);
          // console.log(
          //   "saveOutSellerRes.saveSellerStatus",
          //   saveOutSellerRes.saveSellerStatus !== "00"
          // );
          if (saveOutSellerRes.saveSellerStatus !== "00") {
            throw saveOutSellerRes.saveSellerMSG ?? saveOutSellerRes.message;
          }
          transferId = saveOutSellerRes.transferId;
        })
        .catch((ex) => {
          message.error(ex);
          setLoading(false);
          return null;
        });

      if (saveSellerRes === null) return null;

      const saveBuyerRes = await saveOutBuyerFunc(
        props,
        buyerDistrictList,
        user,
        company,
        transferId
      )
        .then((saveOutBuyerRes) => {
          if (saveOutBuyerRes.saveBuyerStatus !== "00")
            throw saveOutBuyerRes.saveBuyerMSG;
          message.success("Buyer information saved.");
          return transferId;
        })
        .catch((ex) => {
          message.error(ex);
          setLoading(false);
          return null;
        });
      return saveBuyerRes;
    }
  } catch (error) {
    error && message.error(error?.toString());
    return null;
  }
};

export const saveBulkOutSellerFunc = async (
  branch,
  user,
  company,
  props,
  vehicleInfoList
) => {
  let seller = {
    address1: "",
    address2: "",
    address3: "",
    correspondentAddress: "",
    correspondentEmail: "",
    correspondentMobileNo: "",
    dateOfBirth: "",
    district: "",
    email: "",
    gender: "",
    identificationNo: "",
    name: "",
    phoneNo: "",
    postcode: "",
    repIc: "",
    repIc2: "",
    repName: "",
    state: "",
  };
  const saveOutSellerRes = await saveBulkOutSeller(
    branch.id,
    user.username,
    company.coRegNo,
    true,
    seller,
    props.transactionType,
    vehicleInfoList
  );

  return saveOutSellerRes;
};

export const saveBulkOutBuyerFunc = async (
  props,
  buyerDistrictList,
  user,
  company,
  bulkTransferId
) => {
  const buyerDistrictCode = props.buyer?.district
    ? buyerDistrictList.find(
        (district) =>
          district?.districtName.toUpperCase() ===
          props.buyer?.district.toUpperCase()
      )?.districtCode
    : "";

  let buyerInfo = {
    address1: props.buyer?.address1,
    address2: props.buyer?.address2,
    address3: props.buyer?.address3,
    correspondentAddress1:
      props.buyer?.isAddressDifferent === true
        ? props.buyer?.correspondentAddress1
        : props.buyer?.address1,
    correspondentAddress2:
      props.buyer?.isAddressDifferent === true
        ? props.buyer?.correspondentAddress2
        : props.buyer?.address2,
    correspondentAddress3:
      props.buyer?.isAddressDifferent === true
        ? props.buyer?.correspondentAddress3
        : props.buyer?.address3,
    correspondentDistrict: buyerDistrictCode,
    correspondentEmail: props.buyer?.correspondentEmail,
    correspondentMobileNo: props.buyer?.correspondentMobileNo,
    correspondentPostcode:
      props.buyer?.isAddressDifferent === true
        ? props.buyer?.correspondentPostcode
        : props.buyer?.postcode,
    correspondentState:
      props.buyer?.isAddressDifferent === true
        ? props.buyer?.correspondentState
        : props.buyer?.state,
    dateOfBirth: props.buyer?.dateOfBirth,
    city: props.buyer?.city,
    mykadCity: props.buyer?.mykadCity,
    district: buyerDistrictCode,
    email: props.buyer?.email,
    gender: props.buyer?.gender,
    identificationNo: props.buyer?.identificationNo,
    name: props.buyer?.name,
    phoneNo: props.buyer?.phoneNo,
    mobileNo: props.buyer?.mobileNo,
    postcode: props.buyer?.postcode,
    state: props.buyer?.state,
    buyerType: props.buyer?.buyerType,
    citizenship: props?.buyer?.citizenship?.replace(/\0.*$/, "").toUpperCase(),
  };

  const saveOutBuyerRes = await saveBulkOutBuyer(
    buyerInfo,
    user.username,
    company.coRegNo,
    // null,
    props.transactionType === OUT_TRANSACTION_TYPES_IDS.D2C
      ? buyerInfo?.identificationNo
      : null,
    bulkTransferId
  );

  return saveOutBuyerRes;
};

export const saveOutSellerFunc = async (
  branch,
  user,
  company,
  props,
  vehicle
) => {
  let seller = {
    address1: "",
    address2: "",
    address3: "",
    correspondentAddress: "",
    correspondentEmail: "",
    correspondentMobileNo: "",
    dateOfBirth: "",
    district: "",
    email: "",
    gender: "",
    identificationNo: "",
    name: "",
    phoneNo: "",
    postcode: "",
    repIc: "",
    repIc2: "",
    repName: "",
    state: "",
  };
  const saveOutSellerRes = await saveOutSeller(
    branch.id,
    user.username,
    company.coRegNo,
    true,
    seller,
    props.transactionType,
    vehicle
  );
  return saveOutSellerRes;
};

export const saveOutBuyerFunc = async (
  props,
  buyerDistrictList,
  user,
  company,
  transferId
) => {
  const buyerDistrictCode = props.buyer?.district
    ? buyerDistrictList.find(
        (district) =>
          district?.districtName.toUpperCase() ===
          props.buyer?.district.toUpperCase()
      )?.districtCode
    : "";

  let buyerInfo = {
    address1: props.buyer?.address1,
    address2: props.buyer?.address2,
    address3: props.buyer?.address3,
    correspondentAddress1:
      props.buyer?.isAddressDifferent === true
        ? props.buyer?.correspondentAddress1
        : props.buyer?.address1,
    correspondentAddress2:
      props.buyer?.isAddressDifferent === true
        ? props.buyer?.correspondentAddress2
        : props.buyer?.address2,
    correspondentAddress3:
      props.buyer?.isAddressDifferent === true
        ? props.buyer?.correspondentAddress3
        : props.buyer?.address3,
    correspondentDistrict: buyerDistrictCode,
    correspondentEmail: props.buyer?.correspondentEmail,
    correspondentMobileNo: props.buyer?.correspondentMobileNo,
    correspondentPostcode:
      props.buyer?.isAddressDifferent === true
        ? props.buyer?.correspondentPostcode
        : props.buyer?.postcode,
    correspondentState:
      props.buyer?.isAddressDifferent === true
        ? props.buyer?.correspondentState
        : props.buyer?.state,
    dateOfBirth: props.buyer?.dateOfBirth,
    city: props.buyer?.city,
    mykadCity: props.buyer?.mykadCity,
    district: buyerDistrictCode,
    email: props.buyer?.email,
    gender: props.buyer?.gender,
    identificationNo: props.buyer?.identificationNo,
    name: props.buyer?.name,
    phoneNo: props.buyer?.phoneNo,
    mobileNo: props.buyer?.mobileNo,
    postcode: props.buyer?.postcode,
    state: props.buyer?.state,
    buyerType: props.buyer?.buyerType || 1,
    citizenship: props?.buyer?.citizenship?.replace(/\0.*$/, "").toUpperCase(),
  };

  const saveOutBuyerRes = await saveOutBuyer(
    buyerInfo,
    user.username,
    company.coRegNo,
    // null,
    transferId,
    props.transactionType === OUT_TRANSACTION_TYPES_IDS.D2C
      ? buyerInfo?.identificationNo
      : null
  );
  return saveOutBuyerRes;
};

export const validateAndCreateAccountFunc = async (props) => {
  let buyerInfo = {
    correspondentAddress1:
      props.buyer?.isAddressDifferent === true
        ? props.buyer?.correspondentAddress1
        : props.buyer?.address1,
    correspondentAddress2:
      props.buyer?.isAddressDifferent === true
        ? props.buyer?.correspondentAddress2
        : props.buyer?.address2,
    correspondentAddress3:
      props.buyer?.isAddressDifferent === true
        ? props.buyer?.correspondentAddress3
        : props.buyer?.address3,
    correspondentEmail: props.buyer?.correspondentEmail,
    correspondentMobileNo: props.buyer?.correspondentMobileNo,
    correspondentPostcode:
      props.buyer?.isAddressDifferent === true
        ? props.buyer?.correspondentPostcode
        : props.buyer?.postcode,
    correspondentState:
      props.buyer?.isAddressDifferent === true
        ? props.buyer?.correspondentState
        : props.buyer?.state,
    identificationNo: props.buyer?.identificationNo,
    name: props.buyer?.name,
  };

  const isAccountExistRes = await isAccountExist(buyerInfo.identificationNo);

  if (isAccountExistRes.status === false) {
    const { formattedDob, isMale } = breakdownIc(buyerInfo.identificationNo);
    let localFormattedDob = moment(new Date("" + formattedDob)).format(
      "DD-MM-YYYY"
    );

    const create1MIDAccountRes = await create1MIDAccount(
      buyerInfo.correspondentAddress1,
      buyerInfo.correspondentAddress2,
      buyerInfo.correspondentAddress3,
      localFormattedDob,
      "",
      "",
      buyerInfo.city, // buyerInfo.correspondentDistrict,
      buyerInfo.correspondentEmail,
      buyerInfo.correspondentEmail,
      isMale ? "MALE" : "FEMALE",
      buyerInfo.correspondentMobileNo,
      buyerInfo.name,
      buyerInfo.identificationNo,
      false,
      buyerInfo.correspondentMobileNo,
      buyerInfo.correspondentPostcode,
      "",
      "",
      buyerInfo.correspondentState
    );

    if (!create1MIDAccountRes.status) {
      let msg = create1MIDAccountRes.message;

      if (
        "Sorry, an account already exists with email address - " ===
        create1MIDAccountRes.message
      ) {
        msg += buyerInfo.correspondentEmail;
      }

      throw msg;
    }
  }
};

export const outBulkTransferInquiry = async (
  setBulkTransferId,
  bulkTransferId,
  transferIdList,
  companyId,
  onResetData,
  isOutTxn
) => {
  const saveBuyerPayload = {
    transferIdList: bulkTransferId == null ? transferIdList : [],
    uId: "",
    bulkTransferId: bulkTransferId ?? null,
    companyId: companyId,
    moduleType: isOutTxn
      ? NOTIFICAION_APPROVAL_TYPE.BULK_OUT_SUBMISSION
      : NOTIFICAION_APPROVAL_TYPE.BULK_I2I_SUBMISSION,
    isOutTxn: isOutTxn,
  };

  const m2mProceedRes = await OUTBulkTransferInquiryWithJPJ(saveBuyerPayload);
  if (m2mProceedRes.statusCode !== "00") {
    message.error("Processing Failed. Please Try Again.");
    return null;
  } else {
    message.info("Transfer in Progress.");
    await onResetData();
    setBulkTransferId(m2mProceedRes.bulkTransferId);
    return m2mProceedRes;
  }
};

export const outTransferInquiry = async (nric, transferId) => {
  const response = await saveOutSellerBuyerInquiry(nric, transferId);
  if (response.statusCode !== TRANSACTION_OUT_RESPONSE_CODES.SUCCESS) {
    message.error(response.statusMSG);
    return null;
  } else {
    return response;
  }
};

export const outBulkProcessPayment = async (
  transferIdList,
  onResetData,
  dealerCompanyROC,
  bulkTrfId,
  isOutTxn,
  setBulkDetails,
  setIsM2mBulkCompleted,
  setIsApprovalStatusModalVisible,
  totalVehicle,
  setBulkTransferId
) => {
  const payload = {
    transferIdList: bulkTrfId == null ? transferIdList : [],
    uId: "",
    bulkTransferId: bulkTrfId ?? null,
    dealerCompanyROC: dealerCompanyROC,
    moduleType: isOutTxn
      ? NOTIFICAION_APPROVAL_TYPE.BULK_OUT_PAYMENT
      : NOTIFICAION_APPROVAL_TYPE.BULK_I2I_PAYMENT,
    isOutTxn: isOutTxn,
  };

  const m2mProceedRes = await bulkProcessPaymentOUT(payload);
  if (m2mProceedRes.status !== "00") {
    message.error("Processing Failed. Please Try Again.");
    m2mProceedRes.message && message.error(m2mProceedRes.message);
    if (m2mProceedRes.message === "Insufficient balance to process payment") {
      const bulkDetails = {
        inquiryFailed: m2mProceedRes.totalVehicle
          ? m2mProceedRes.totalVehicle
          : totalVehicle,
        inquirySuccess: 0,
        totalVehicle: m2mProceedRes.totalVehicle
          ? m2mProceedRes.totalVehicle
          : totalVehicle,
      };
      setBulkDetails(bulkDetails);
      setIsM2mBulkCompleted(true);
      setIsApprovalStatusModalVisible(true);
      Modal.error({
        content: (
          <div>
            You have insufficient prepaid balance. Please{" "}
            <a href={process.env.REACT_APP_RELOAD_URL} target="_blank">
              click here
            </a>{" "}
            to top up, and the amount will be automatically credited to your
            prepaid account.
          </div>
        ),
      });
    }
    return false;
  } else {
    setBulkTransferId(m2mProceedRes.bulkTransferId);
    message.info("Transfer in Progress.");
    await onResetData();
    // setBulkTransferId(m2mProceedRes.bulkTransferId);
    setIsApprovalStatusModalVisible(true);
    return true;
  }
};

export const onPaymentSubmit = async (company, transferId, user) => {
  return await outTransferInquiry(user.nric, transferId)
    .then(async (res) => {
      if (res === null) throw "";

      return await processPaymentOut(company.coRegNo, transferId)
        .then(async (res) => {
          if (res.status !== "true") throw res.message;

          return await outUpdateVehicleDetailsToJPJ(transferId)
            .then((res) => {
              if (res.statusCode !== "00") throw res.statusMSG;
              message.success(`Successfully updated to JPJ`);
              return true;
            })
            .catch((err) => {
              throw err;
            });
        })
        .catch((err) => {
          console.log("err",err)
          if (err === "Fail to process prepaid payment." || err.includes("Insufficient")) {
            Modal.error({
              content: (
                <div>
                  You have insufficient prepaid balance. Please{" "}
                  <a href={process.env.REACT_APP_RELOAD_URL} target="_blank">
                    click here
                  </a>{" "}
                  to top up, and the amount will be automatically credited to
                  your prepaid account.
                </div>
              ),
            });
          } else {
            Modal.error({
              content: err?.toString(),
            });
          }
          throw err;
        })
        .finally(() => {});
    })
    .catch((err) => {
      return false;
    });
};

export const updateToJPJ = async (user, transferId) => {
  return await outTransferInquiry(user.nric, transferId)
    .then(async (res) => {
      if (res === null) throw "";
      message.loading("Proceeding update JPJ");
      return await outUpdateVehicleDetailsToJPJ(transferId)
        .then((res) => {
          if (res.statusCode !== "00") throw res.statusMSG;
          message.success(`Successfully updated to JPJ`);
          return true;
        })
        .catch((err) => {
          Modal.error({
            content: err?.toString(),
          });
          throw err;
        });
    })
    .catch((err) => {
      return false;
    });
};
